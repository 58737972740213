import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Container,
  TextField,
  Card,
  CardContent,
  InputBase,
  IconButton,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { brown } from "@mui/material/colors";
import { API_URLS } from "../utils/config";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import Skeleton from "react-loading-skeleton";
import OfferCardComponent from "../common/historyOfferCard";

const theme = createTheme({
  palette: {
    primary: brown,
  },
  palette: {
    primary: brown,
  },
});

const windowHeight = window.innerHeight;

const useStyles = makeStyles(() => ({
  activePage: {
    backgroundColor: "#463636 !important",
    color: "#fff !important",
  },
  pageButton: {
    margin: "0 5px",
  },
  paginationContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "1rem",
  },
  activePage: {
    backgroundColor: "#463636 !important",
    color: "#fff !important",
  },
  container: {
    marginBottom: "1%",
  },
  image: {
    marginTop: '5rem',
    width: "40",
    height: "300px",
    backgroundColor:'#CDBDB7',
    padding:'10px'
  },
  pageButton: {
    margin: "0 5px",
  },
}));

const History = () => {
  const [pastOffers, setPastOffers] = useState([]);
  const [currentOffers, setCurrentOffers] = useState([]);
  const [upcomingOffers, setUpcomingOffers] = useState([]);
  const [selectedType, setSelectedType] = useState("past");
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filtered, setFiltered] = useState(false);
  const itemsPerPage = 9;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchData = async (start, end) => {
      const isAdmin = localStorage.getItem("isAdmin");
      let pastUrl, currentUrl, upcomingUrl;

      if (isAdmin === "false") {
        const id = localStorage.getItem("hotelId");
        pastUrl = `${API_URLS.BASE_URL}/past/${id}`;
        currentUrl = `${API_URLS.BASE_URL}/current/${id}`;
        upcomingUrl = `${API_URLS.BASE_URL}/upcoming/${id}`;
      } else {
        pastUrl = `${API_URLS.BASE_URL}/all-past`;
        currentUrl = `${API_URLS.BASE_URL}/all-current`;
        upcomingUrl = `${API_URLS.BASE_URL}/all-upcoming`;
      }

      try {
        const token = localStorage.getItem("token");
        const fetchOffers = async (url) => {
          const response = await fetch(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          return data.data;
        };

        const pastData = await fetchOffers(pastUrl);
        const currentData = await fetchOffers(currentUrl);
        const upcomingData = await fetchOffers(upcomingUrl);
        const filterStart = start ? new Date(start).setHours(0, 0, 0, 0) : null;
        const filterEnd = end ? new Date(end).setHours(23, 59, 59, 999) : null;
        const filterByDate = (offers) => {
          return offers.filter((offer) => {
            const offerStart = new Date(offer.startDate).setHours(0, 0, 0, 0);
            const offerEnd = new Date(offer.expireDate).setHours(
              23,
              59,
              59,
              999
            );
            return (
              !filterStart ||
              (offerStart <= filterEnd && offerStart >= filterStart) ||
              !filterEnd ||
              (offerEnd <= filterEnd && offerEnd >= filterStart)
            );
          });
        };

        setPastOffers(filterByDate(pastData));
        setCurrentOffers(filterByDate(currentData));
        setUpcomingOffers(filterByDate(upcomingData));
        setLoading(false);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData(startDate, endDate);
  }, [startDate, endDate]);

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filterOffers = (offers) => {
    return offers.filter(
      (offer) =>
        !searchTerm ||
        (offer.title && offer.title.toLowerCase().includes(searchTerm)) ||
        (offer.description &&
          offer.description.toLowerCase().includes(searchTerm))
    );
  };

  const renderOfferCards = (offers) => {
    const filteredOffers = filterOffers(offers);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOffers = filteredOffers.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    const totalPages = Math.ceil(filteredOffers.length / itemsPerPage);

    const pageNumbers = [];
    const maxPageButtons = 10;
    let startPage =
      Math.floor((currentPage - 1) / maxPageButtons) * maxPageButtons + 1;
    let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (filteredOffers.length === 0) {
      return (
        <Card
              variant="soft"
              style={{
                width: "100%",
                backgroundColor: "#CDBDB7",
                marginTop: "15px",
              }}
            >
              <CardContent>
                <Typography
                  level="title-md"
                  style={{
                    textAlign: "center",
                    color: "#463636",
                    fontWeight: "bold",
                    fontSize: 20,
                    borderRadius: 20,
                    alignItems: "center", // Center items horizontally
                    justifyContent: "center", // Center items vertically
                    display: "flex", // Enable flexbox layout
                    flexDirection: "column", // Arrange items in a column
                    height: "100%",
                  }}
                >
                  <img
                    src={require("../assert/noData.png")}
                    className={classes.image}
                  />
                  <p>No offer available !!</p>
                </Typography>
              </CardContent>
            </Card>
      );
    }

    return (
      <Container style={{ marginBottom: "1%", width: '100%' }}>
        <Grid container spacing={2}>
          {currentOffers.map((offer) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={4}
              key={offer.id}
              style={{ marginBottom: "1%"}}
            >
              <OfferCardComponent offer={offer} offerType={selectedType} />
            </Grid>
          ))}
        </Grid>
        <ThemeProvider theme={theme}>
          {totalPages > 1 && (
            <div
              style={{
                float: currentOffers.length <= 3 ? "right" : undefined,
                bottom: currentOffers.length <= 3 ? "6px" : undefined,
                right: "10px",
                position: currentOffers.length <= 3 ? "absolute" : undefined,
                display: currentOffers.length >= 3 ? "flex" : undefined,
                flexDirection: currentOffers.length >= 3 ? "row" : undefined,
                justifyContent:
                  currentOffers.length >= 3 ? "flex-end" : undefined,
                display: "flex",
                flexWrap: "wrap",
                overflowY: "auto",
              }}
            >
              {startPage > 1 && (
                <Button onClick={() => paginate(startPage - 1)}>
                  &laquo; Prev
                </Button>
              )}
              {pageNumbers.map((pageNumber) => (
                <Button
                  key={pageNumber}
                  onClick={() => paginate(pageNumber)}
                  className={
                    pageNumber === currentPage
                      ? classes.activePage
                      : classes.pageButton
                  }
                >
                  {pageNumber}
                </Button>
              ))}
              {endPage < totalPages && (
                <Button onClick={() => paginate(endPage + 1)}>
                  Next &raquo;
                </Button>
              )}
            </div>
          )}
        </ThemeProvider>
      </Container>
    );
  };

  return (
    <div style={{ margin: "2%" }}>
      <h2 style={{ color: "#463636", fontWeight: "bold" }}>
        History
        <div
          style={{
            display: "flex",
            gap: "1rem",
            marginBottom: "2rem",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              gap: "1rem",
              width: isSmallScreen ? "100%" : "auto",
            }}
          >
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              sx={{ width: isSmallScreen ? "100%" : "auto", cursor: "pointer" }}
            />
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              sx={{ width: isSmallScreen ? "100%" : "auto", cursor: "pointer" }}
            />
            <Button
              variant="contained"
              onClick={() => setFiltered(!filtered)}
              sx={{
                backgroundColor: "#FF6B09",
                color: "white",
                width: isSmallScreen ? "100%" : "auto",
              }}
            >
              Search
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              width: "80%",
              flexDirection: isSmallScreen ? "column" : "row",
            }}
          >
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "2px",
                width: "80%",
              }}
              placeholder="Search" style={{paddingLeft:20}}
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {!isSmallScreen && (
              <IconButton
                type="button"
                sx={{
                  p: "10px",
                  backgroundColor: "#FF6B09",
                  color: "white",
                  borderRadius: "10px",
                  marginLeft: "10px",
                  marginRight: "30px",
                }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            )}
          </div>
        </div>
      </h2>

      {loading ? (
        <Grid container spacing={3}>
          {Array.from(new Array(6)).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className={classes.card}>
                <Skeleton height={140} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    <Skeleton />
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    <Skeleton count={3} />
                  </Typography>
                  <Button status={false}>
                    <Skeleton width={100} />
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "2%",
            gap: "1%",
            backgroundColor: "#E1D2CB",
            marginBottom: "2%",
            borderRadius: "10px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleTypeChange("past")}
            style={{
              flex: 1,
              backgroundColor: selectedType === "past" ? "#FF6B09" : "#E1D2CB",
              color: selectedType === "past" ? "white" : "#463636",
              height: "60px",
              fontSize: "15px",
              borderRadius: "10px",
            }}
          >
            <b>Past</b>
            <span
              style={{ fontSize: "10px", fontWeight: "bold", marginTop: "1%" }}
            >
              {" "}
              ({pastOffers.length})
            </span>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleTypeChange("current")}
            style={{
              flex: 1,
              backgroundColor:
                selectedType === "current" ? "#FF6B09" : "#E1D2CB",
              color: selectedType === "current" ? "white" : "#463636",
              borderRadius: "10px",
            }}
          >
            <b>Current</b>
            <span
              style={{ fontSize: "10px", fontWeight: "bold", marginTop: "1%" }}
            >
              {" "}
              ({currentOffers.length})
            </span>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleTypeChange("upcoming")}
            style={{
              flex: 1,
              backgroundColor:
                selectedType === "upcoming" ? "#FF6B09" : "#E1D2CB",
              color: selectedType === "upcoming" ? "white" : "#463636",
              borderRadius: "10px",
            }}
          >
            <b>Upcoming</b>
            <span
              style={{ fontSize: "10px", fontWeight: "bold", marginTop: "1%" }}
            >
              {" "}
              ({upcomingOffers.length})
            </span>
          </Button>
        </div>
      )}

      {selectedType === "past" && renderOfferCards(pastOffers)}
      {selectedType === "current" && renderOfferCards(currentOffers)}
      {selectedType === "upcoming" && renderOfferCards(upcomingOffers)}
    </div>
  );
};

export default History;
