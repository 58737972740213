import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { brown } from "@mui/material/colors";
import OfferCardComponent from "../common/OfferCardComponent";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { API_URLS } from "../utils/config";
import ContentLoader from "react-content-loader";

const theme = createTheme({
  palette: {
    success: brown,
  },
});

const useStyles = makeStyles(() => ({
  shimmerContainer: {
    width: "100%",
    marginTop: "1%",
    borderRadius: "10px",
  },
  loader: {
    margin: "auto",
    marginTop: "30%",
    width: "50px",
    aspectRatio: "1",
    borderRadius: "50%",
    border: "8px solid #0000",
    borderRightColor: "#ffa50097",
    position: "relative",
    animation: "$l24 1s infinite linear",
  },
  "@keyframes l24": {
    "100%": { transform: "rotate(1turn)" },
  },
  "@global": {
    ".loader::before, .loader::after": {
      content: '""',
      position: "absolute",
      inset: "-8px",
      borderRadius: "50%",
      border: "inherit",
      animation: "inherit",
      animationDuration: "2s",
    },
    ".loader::after": {
      animationDuration: "4s",
    },
  },
  image: {
    width: "30%",
    height: "300px",
    // marginTop: 20,
  },
}));

const ShimmerEffectCard = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={200}
    viewBox="0 0 300 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="10" ry="10" width="300" height="200" />
  </ContentLoader>
);

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const TodayOfferComponent = () => {
  const [todayOffers, setTodayOffers] = useState([]);
  const [todayPendingOffers, setTodayPendingOffers] = useState([]);
  const [notification, setNotification] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [restaurantData, setRestaurantData] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [open, setOpen] = useState(false);

  const handleAddUserClick = () => {
    navigate("/offer/offer-type");
  };

  const handleNotificationClick = () => {
    fetchNotification();
    setOpen(true);
  };

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin");
    if (isAdmin === "true") {
      setAdmin(true);
    } else {
      fetchRestaurantData();
      fetchNotification();
    }
  }, []);

  const fetchRestaurantData = async () => {
    try {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("hotelId");
      const response = await fetch(
        `${API_URLS.BASE_URL}/total-number-of-messages/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch restaurant data");
      }
      const data = await response.json();
      const number = 200 - data.data;

      if (number <= 10) {
        const mobileNumber = "8320613343";
        const emailId = "info@surprises.world";
        if (number === 0) {
          // Display a modal with only contact info and no 'OK' button
          const contactInfo = `Your message limit has been exhausted. Please contact us at:\n\nMobile Number: ${mobileNumber}\nEmail ID: ${emailId}`;
          const emailLink = `<a href="mailto:${emailId}" target="_blank">${emailId}</a>`;
          document.body.innerHTML += `
            <div style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; padding: 20px; box-shadow: 0px 0px 10px rgba(0,0,0,0.1);">
              <p>${contactInfo}</p>
              <p>Click <a href="mailto:${emailId}" target="_blank">here</a> to email us.</p>
            </div>
          `;
        } else if (
          window.confirm("Your message limit is low. Do you want to continue?")
        ) {
          alert(`Mobile Number: ${mobileNumber}\nEmail ID: ${emailId}`);
        }
      }

      setRestaurantData(number);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleDeleteConfirmation();
    fetchNotification();
  };

  const handleDeleteConfirmation = () => {
    try {
      const token = localStorage.getItem("token");
      const hotelId = localStorage.getItem("hotelId");
      fetch(
        `${API_URLS.BASE_URL}/delete-offer-notification?hotelId=${hotelId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete notification");
        }
        fetchNotification();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const navigateToAcceptedOffers = () => {
    navigate("/accepted-offers");
  };

  const navigateToPendingOffers = () => {
    navigate("/pending-offers");
  };

  const fetchNotification = () => {
    const token = localStorage.getItem("token");
    const hotelId = localStorage.getItem("hotelId");

    fetch(`${API_URLS.BASE_URL}/offer-notification?hotelId=${hotelId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setNotification(data.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(() => {
    const fetchTodayOffers = () => {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("hotelId");
      const isAdmin = localStorage.getItem("isAdmin");
      if (isAdmin === "false") {
        fetch(`${API_URLS.BASE_URL}/today-offer/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            setTodayOffers(data.data);
          })
          .catch((error) => {
            setError(error.message);
          });
      } else {
        fetch(`${API_URLS.BASE_URL}/today-offer`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            setTodayOffers(data.data);
            setLoading(false);
          })
          .catch((error) => {
            setError(error.message);
          });
      }
    };
    fetchTodayOffers();
  }, []);

  useEffect(() => {
    const fetchTodayPendingOffers = () => {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("hotelId");
      const isAdmin = localStorage.getItem("isAdmin");

      if (isAdmin === "false") {
        fetch(`${API_URLS.BASE_URL}/today-offer-notResponse/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            setTodayPendingOffers(data.data);
            setLoading(false);
          })
          .catch((error) => {
            setError(error.message);
          });
      } else {
        fetch(`${API_URLS.BASE_URL}/today-offer-notResponse`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            setTodayPendingOffers(data.data);
          })
          .catch((error) => {
            setError(error.message);
          });
      }
    };
    fetchTodayPendingOffers();
  }, []);

  if (loading) {
    return (
      <Container>
        <Typography
          variant="h5"
          gutterBottom
          style={{ color: "#463636", marginTop: "20px" }}
        >
          Loading today's offers...
        </Typography>
        <Grid container spacing={2}>
          {[...Array(3)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ShimmerEffectCard />
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="h5"
          gutterBottom
          style={{ color: "#463636", marginTop: "20px" }}
        >
          Loading today's pending offers...
        </Typography>
        <Grid container spacing={2}>
          {[...Array(3)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ShimmerEffectCard />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

  return (
    <Box
      style={{
        marginTop: "2%",
        marginBottom: "2%",
        marginLeft: windowHeight < 600 ? "14px" : 0,
        backgroundColor: "#FEF0E7",
      }}
    >
      {!admin && (
        <h3 style={{ marginLeft: "2%", marginTop: "-5px" }}>
          <Typography
            variant="contained"
            sx={{
              backgroundColor: "#FF6B09",
              color: "white",
              float: "left",
              padding: 2,
              borderRadius: "10px",
              flex: "0 0 auto",
              textAlign: "center",
              justifyContent: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <WhatsAppIcon
              style={{
                paddingRight: "10px",
                fontSize: 40,
                color: "#00cc00",
                verticalAlign: "middle",
              }}
            />
            <span style={{ fontSize: "1rem", color: "white" }}>
              The message limit is: {restaurantData}
            </span>
          </Typography>
          <NotificationAddIcon
            sx={{
              marginLeft: "51%",
              height: "60px",
              width: "40px",
              cursor: "pointer",
            }}
            onClick={handleNotificationClick}
          />
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FF6B09",
                color: "white",
                float: "right",
                height: "60px",
                width: "20%",
                borderRadius: "10px",
                marginRight: "2%",
                "&:hover": {
                  backgroundColor: "#463636",
                },
              }}
              onClick={handleAddUserClick}
            >
              <b>Create Offer</b>
            </Button>
          </ThemeProvider>
        </h3>
      )}

      <Container sx={{ marginTop: "5%" }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{ color: "#463636", marginTop: "20px" }}
        >
          Today accepted offers
          {todayOffers.length > 3 && (
            <span
              href="#"
              style={{
                float: "right",
                color: "#463636",
                textDecoration: "underline",
              }}
              onClick={navigateToAcceptedOffers}
            >
              See all
            </span>
          )}
        </Typography>
        <Grid container spacing={2}>
          {todayOffers.length > 0 ? (
            todayOffers.slice(0, 3).map((offer) => (
              <Grid item xs={12} sm={6} md={4} key={offer.id}>
                <OfferCardComponent offer={offer} />
              </Grid>
            ))
          ) : (
            <Card
              variant="soft"
              style={{
                width: "100%",
                backgroundColor: "#CDBDB7",
                marginTop: "15px",
                marginLeft: "1%",
              }}
            >
              <CardContent>
                <Typography
                  level="title-md"
                  style={{
                    textAlign: "center",
                    color: "#463636",
                    fontWeight: "bold",
                    fontSize: 20,
                    borderRadius: 10,
                    alignItems: "center", // Center items horizontally
                    justifyContent: "center", // Center items vertically
                    display: "flex", // Enable flexbox layout
                    flexDirection: "column", // Arrange items in a column
                    height: "100%",
                  }}
                >
                  <img
                    src={require("../assert/noData.png")}
                    className={classes.image}
                  />
                  <p>No offer available !!</p>
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Container>

      <Container>
        <Typography
          variant="h5"
          gutterBottom
          style={{ color: "#463636", marginTop: "20px" }}
        >
          Other offer list
          {todayPendingOffers.length > 3 && (
            <span
              href="#"
              style={{
                float: "right",
                color: "#463636",
                textDecoration: "underline",
              }}
              onClick={navigateToPendingOffers}
            >
              See all
            </span>
          )}
        </Typography>
        <Grid container spacing={2}>
          {todayPendingOffers.length > 0 ? (
            todayPendingOffers.slice(0, 3).map((offer) => (
              <Grid item xs={12} sm={6} md={4} key={offer.id}>
                <OfferCardComponent offer={offer} />
              </Grid>
            ))
          ) : (
            <Card
              variant="soft"
              style={{
                width: "100%",
                backgroundColor: "#CDBDB7",
                marginTop: "15px",
                marginLeft: "1%",
              }}
            >
              <CardContent>
                <Typography
                  level="title-md"
                  style={{
                    textAlign: "center",
                    color: "#463636",
                    fontWeight: "bold",
                    fontSize: 20,
                    borderRadius: 10,
                    alignItems: "center", // Center items horizontally
                    justifyContent: "center", // Center items vertically
                    display: "flex", // Enable flexbox layout
                    flexDirection: "column", // Arrange items in a column
                    height: "100%",
                  }}
                >
                  <img
                    src={require("../assert/noData.png")}
                    className={classes.image}
                  />
                  <p>No offer available !!</p>
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Notifications</DialogTitle>
        <DialogContent dividers>
          {error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <List>
              {notification.map((notification, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={notification.offerDescription || "No Title"}
                    secondary={
                      <>
                        {notification.status || "No Message"} by{" "}
                        {notification.userName || "Unknown User"}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TodayOfferComponent;
