import React, { useState, useEffect } from 'react';
import { Grid, InputBase, IconButton, Button, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, ThemeProvider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme } from '@mui/material/styles';
import brown from '@mui/material/colors/brown';
import { useNavigate } from 'react-router-dom';
import { API_URLS } from '../utils/config';
import { makeStyles } from '@mui/styles';
import ContentLoader from 'react-content-loader';

// Create theme with a success color
const theme = createTheme({
  palette: {
    success: brown,
  },
});

const useStyles = makeStyles(() => ({
  shimmerContainer: {
    width: '100%',
    marginTop: '1%',
    borderRadius: '10px',
  },
}));

const ShimmerEffect = () => (
  <ContentLoader 
    speed={2}
    width="100%"
    height={60}
    viewBox="0 0 100% 60"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="10" ry="10" width="20%" height="40" />
    <rect x="25%" y="0" rx="10" ry="10" width="20%" height="40" />
    <rect x="50%" y="0" rx="10" ry="10" width="20%" height="40" />
    <rect x="75%" y="0" rx="10" ry="10" width="20%" height="40" />
  </ContentLoader>
);

const UserListComponent = () => {
  const [userList, setUserList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const token = localStorage.getItem('token');
        const hotelId = localStorage.getItem('hotelId');
        const isAdmin = localStorage.getItem('isAdmin') === 'true'; // Convert string to boolean
        setAdmin(isAdmin);

        const url = isAdmin
          ? `${API_URLS.BASE_URL}/list-of-users`
          : `${API_URLS.BASE_URL}/nearby?hotelId=${hotelId}`;

        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          setError("No user list displayed");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setUserList(data.data);
        setFilteredData(data.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUserList();
  }, []);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = userList.filter(user =>
      user.userName.toLowerCase().includes(searchTerm) ||
      user.email?.toLowerCase().includes(searchTerm) ||
      user.mobileNumber?.includes(searchTerm) || 
      user.city?.toLowerCase().includes(searchTerm) ||
      user.area?.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
    setPage(0); 
  };

  const handleAddUserClick = () => {
    navigate('/add-user');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <div className={classes.shimmerContainer}>
        {Array.from({ length: 10 }).map((_, index) => (
          <ShimmerEffect key={index} />
        ))}
      </div>
    );
  }

  return (
    <div style={{ padding: '1%', width: '100%', height: '100%', boxSizing: 'border-box' }}>
      <h2 style={{ color: '#463636', margin: '20px 0' }}>Users</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={9}>
          <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FCB98E', borderRadius: '10px', padding: '0 10px' }}>
            <InputBase
              sx={{ flex: 1 }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ width: '100%' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <ThemeProvider theme={theme}>
            <Button 
              variant="contained" 
              color="success" 
              onClick={handleAddUserClick} 
              sx={{ backgroundColor: '#FF6B09', color: 'white', width: '100%', height: '100%', borderRadius: "10px" }}
            >
              <b>Add New User</b>
            </Button>
          </ThemeProvider>
        </Grid>
      </Grid>
     
      <Table sx={{ backgroundColor: '#FCB98E', marginTop: '1%', borderRadius: '10px', overflowX: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell><b>Name</b></TableCell>
            <TableCell><b>Email</b></TableCell>
            <TableCell><b>Phone No</b></TableCell>
            <TableCell><b>Location</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredData
          ).length > 0 ? (
            (rowsPerPage > 0
              ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredData
            ).map((user) => (
              <TableRow key={user.id}>
                <TableCell><b>{user.userName}</b></TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.mobileNumber}</TableCell>
                <TableCell>{user.area}, {user.city}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No data found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
   
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default UserListComponent;
