import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardMedia,
  Modal,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PersonList from "../components/personList";
import Box from "@mui/material/Box";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import { API_URLS } from "../utils/config";
import { MoreVert } from "@mui/icons-material";

const useStyles = makeStyles({
  card: {
    flexDirection: "row",
    marginRight: "1%",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  border: "2px solid #000",
  boxShadow: 24,
};

const HistoryOfferCardComponent = ({ offer, offerType }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [offersUserList, setOffersUserList] = useState([]);
  const [error, setError] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [acceptedUsersCount, setAcceptedUsersCount] = useState(0);
  const [rejectedUsersCount, setRejectedUsersCount] = useState(0);
  const [notRespondedUsersCount, setNotRespondedUsersCount] = useState(0);
  const [admin, setAdmin] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin");
    if (isAdmin === "true") {
      setAdmin(true);
    }
  }, []);

  useEffect(() => {
    fetchOffersUserList(offer.id);
  }, [offer.id]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${API_URLS.BASE_URL}/offer/image/${offer.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setImageSrc(imageUrl);
      } catch (error) {
        console.error("Failed to fetch image", error);
      }
    };

    fetchImage();
  }, [offer.id]);

  const fetchOffersUserList = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_URLS.BASE_URL}/user-list-by-offer/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const acceptedCount = data.data.filter(
        (user) => user.status === "ACCEPTED"
      ).length;
      const rejectedCount = data.data.filter(
        (user) => user.status === "REJECTED"
      ).length;
      const notRespondedCount = data.data.filter(
        (user) => user.status === "NOTRESPONDED"
      ).length;

      setOffersUserList(data.data);
      setAcceptedUsersCount(acceptedCount);
      setRejectedUsersCount(rejectedCount);
      setNotRespondedUsersCount(notRespondedCount);
      setSelectedUsers(data.data.map((entry) => entry.user.id));
      return data.data;
    } catch (error) {
      setError(error.message);
    }
  };

  const handleOpen = (id) => {
    setOpen(true);
    fetchOffersUserList(id);
  };

  const handleClose = () => {
    fetchOffersUserList(offer.id);
    setOpen(false);
  };

  const handleSelectUsers = async (users) => {
    setSelectedUsers(users);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_URLS.BASE_URL}/add-new-user-offer/${offer.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user: users,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    fetchOffersUserList(offer.id);
  };

  const handleActionButtonClick = async (id, status) => {
    const users = await fetchOffersUserList(id);
    const filteredUsers = users.filter((user) => user.status === status);
    setModalData(filteredUsers);
    setModalOpen(true);
  };

  const handleReactive = (offerId) => {
    navigate(`/re-active-offer/reActiveOfferId=${offerId}`);
  };

  const renderActionButton = () => {
    switch (offerType) {
      case "past":
        return (
          <EditIcon
            onClick={() => handleReactive(offer.id)}
            style={{ marginTop: "8px", cursor: "pointer" }}
          />
        );
      case "current":
      case "upcoming":
        return (
          <>
            <MoreVertIcon
              onClick={() => handleOpen(offer.id)}
              style={{ marginTop: "8px", cursor: "pointer" }}
            />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                width: "70%",
                marginLeft: "20%",
                marginTop: "3%",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "0 !important",
                },
                "-ms-overflow-style": "none",
                scrollbarWidth: "none",
              }}
            >
              <Box>
                <button
                  onClick={handleClose}
                  style={{
                    float: "right",
                    fontWeight: "bold",
                    marginTop: "1%",
                    marginRight: "1%",
                  }}
                >
                  X
                </button>
                <PersonList
                  onSelectUsers={handleSelectUsers}
                  selectedUsers={selectedUsers}
                  onCloseModal={handleClose}
                />
              </Box>
            </Modal>
          </>
        );
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <>
      <Card
        className={classes.card}
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "#CDBDB7",
          borderRadius: "7%",
        }}
      >
        <CardContent sx={{ padding: "0%", paddingBottom: "0%" }}>
          <div style={{ flexDirection: "row" }}>
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#463636",
                    padding: "3%",
                    display: "block",
                    maxWidth: "300px",
                    lineHeight: "1.5",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <b>
                    {offer.subTypeDescription
                      ? offer.subTypeDescription
                      : "Other Offer Type"}
                  </b>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#463636", paddingLeft: "3%" }}
                >
                  <b>Created date : {formatDate(offer.createdDate)}</b>
                </Typography>
                {admin && (
                  <Typography
                    variant="h7"
                    sx={{ color: "#463636", paddingLeft: "3%" }}
                  >
                    <b>Created by : {offer.hotel}</b>
                  </Typography>
                )}
              </Grid>
              {!admin && (
                <Grid
                  item
                  xs={1}
                  sx={{ marginTop: "3px", justifyContent: "center" }}
                >
                  {renderActionButton()}
                </Grid>
              )}
            </Grid>
          </div>
          <Grid
            container
            sx={{
              backgroundColor: "#FF6B09",
              height: "auto",
              marginTop: "3%",
              width: "auto",
            }}
          >
            <Grid item xs={6} sx={{ color: "white", padding: "3%" }}>
              <Typography
                variant="body1"
                sx={{
                  display: "-webkit-box",
                  maxWidth: "200px",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                <b>
                  {offer.title
                    ? offer.title
                    : "Title is not available for this offer type"}
                </b>
              </Typography>
              <Typography variant="body2">
                <b> Type: {offer.subTypeName}</b>
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ top: "-10px", position: "relative" }}>
              <CardMedia
                component="img"
                sx={{
                  width: "150px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "50%",
                  transform: "rotate(343deg)",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
                image={imageSrc}
                alt="Uploaded Image"
              />
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            sx={{ marginLeft: "10px", marginTop: "10px", color: "#463636" }}
          >
            <b>Start Date:</b>
            <Typography
              variant="body2"
              sx={{ float: "right", marginRight: "10px", color: "#463636" }}
            >
              <b>Expire Date:</b>
            </Typography>
            <br />
          </Typography>
          <Typography
            variant="body2"
            sx={{ marginLeft: "10px", color: "#463636" }}
          >
            <b>{formatDate(offer.startDate)}</b>
            <Typography
              variant="body2"
              sx={{ float: "right", marginRight: "10px", color: "#463636" }}
            >
              <b>{formatDate(offer.expireDate)}</b>
            </Typography>
            <br />
          </Typography>

          <Grid
            container
            spacing={2}
            sx={{
              padding: "8px",
              borderRadius: "6px",
              marginTop: "4%",
              backgroundColor: "#463636",
              color: "white",
              borderRadiu: "2%",
              width: "95%",
              textAlign: "center",
              marginLeft: "2%",
              marginRight: "2%",
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                color: "white",
                borderRight: "2px solid white",
                paddingLeft: "0px !important",
                paddingTop: "0px !important",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginTop: "1%",
                  marginBottom: "1%",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                onClick={() => handleActionButtonClick(offer.id, "ACCEPTED")}
              >
                <b>{acceptedUsersCount} Accept </b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                color: "white",
                borderRight: "2px solid white",
                paddingLeft: "0px !important",
                paddingTop: "0px !important",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginTop: "1%",
                  marginBottom: "1%",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                onClick={() => handleActionButtonClick(offer.id, "REJECTED")}
              >
                <b>{rejectedUsersCount} Decline</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                color: "white",
                paddingLeft: "0px !important",
                paddingTop: "0px !important",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginTop: "1%",
                  marginBottom: "1%",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleActionButtonClick(offer.id, "NOTRESPONDED")
                }
              >
                <b>{notRespondedUsersCount} Not Respond</b>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            User List
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Mobile No.</TableCell>
                <TableCell>Birthdate</TableCell>
                <TableCell>Accepted/Declined Date:</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modalData.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.user.userName}</TableCell>
                  <TableCell>{user.user.mobileNumber}</TableCell>
                  <TableCell>{formatDate(user.user.dateOfBirth)}</TableCell>
                  <TableCell>
                    {user.dateTime ? formatDate(user.dateTime) : "-"}
                  </TableCell>
                  <TableCell>{user.user.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Modal>
    </>
  );
};

export default HistoryOfferCardComponent;
