import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

const windowHeight = window.innerHeight;

const useStyles = makeStyles(() => ({
    settingContainer: {
        display: "flex",
        flexDirection: "column",
        margin: windowHeight < 600 ? '30px' : 0,
    },
    settingsList: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    settingItem: {
        marginRight: "20px",
        cursor: "pointer",
        textDecoration: "none",
    },
    underlined: {
        textDecoration: "underline",
    },
    settingContent: {
        marginTop: "20px",
    },
}));

const Setting = () => {
    const classes = useStyles();
    const [selectedSetting, setSelectedSetting] = useState("terms");

    useEffect(() => {
        // Set the default selected setting to "terms" when component mounts
        setSelectedSetting("terms");
    }, []);

    const handleSettingClick = (setting) => {
        setSelectedSetting(setting);
    };

    return (
        <div className={classes.settingContainer}>
            <h2>Settings</h2>
            <div className={classes.settingsList}>
                <Grid container spacing={2}>
                    <Grid item className={`${classes.settingItem} ${selectedSetting === "terms" && classes.underlined}`}>
                        <span onClick={() => handleSettingClick("terms")}>Terms & Conditions</span>
                    </Grid>
                    <Grid item className={`${classes.settingItem} ${selectedSetting === "about" && classes.underlined}`}>
                        <span onClick={() => handleSettingClick("about")}>About Us</span>
                    </Grid>
                    <Grid item className={`${classes.settingItem} ${selectedSetting === "help" && classes.underlined}`}>
                        <span onClick={() => handleSettingClick("help")}>Help</span>
                    </Grid>
                    <Grid item className={`${classes.settingItem} ${selectedSetting === "contact" && classes.underlined}`}>
                        <span onClick={() => handleSettingClick("contact")}>Contact Us</span>
                    </Grid>
                </Grid>
            </div>
            {/* Conditional rendering for selected setting */}
            {selectedSetting && (
                <div className={classes.settingContent}>
                    {/* Content for each setting */}
                    {selectedSetting === "terms" && (
                        <div>
                            <h3>Terms & Conditions</h3>
                            <p>
                                The number of lines in a paragraph depends on the size of the browser window. If you resize the browser window, the number of lines in this paragraph will change.
                            </p>
                            <p>Why does your business exist? Why does it matter? What’s the ultimate problem in your industry you’re trying to solve?

                                Ultimately, this is your mission, and you should share it on your About Us page. And, if you’re like many businesses that haven’t clearly defined theirs, now is the time.

                                Why is stating your mission important?</p><p>

                                Because a majority of modern consumers – especially Gen Z – want to know that their purchases align with who they are and that the businesses to which they give their dollars care about the things they care about. (Market research from Alter Agents found that 68% of Gen Z and Millennial buyers believe their purchases are indicative of their personalities and concerns.)

                                Explaining your mission gives your target audience another reason to align themselves with you. Give them your “big why.”

                            </p>
                        </div>
                    )}
                    {selectedSetting === "about" && (
                        <div>
                            <h3>About Us</h3>
                            <p>
                                The number of lines in a paragraph depends on the size of the browser window. If you resize the browser window, the number of lines in this paragraph will change.
                            </p>
                            <p>Why does your business exist? Why does it matter? What’s the ultimate problem in your industry you’re trying to solve?

                                Ultimately, this is your mission, and you should share it on your About Us page. And, if you’re like many businesses that haven’t clearly defined theirs, now is the time.

                                Why is stating your mission important?</p><p>

                                Because a majority of modern consumers – especially Gen Z – want to know that their purchases align with who they are and that the businesses to which they give their dollars care about the things they care about. (Market research from Alter Agents found that 68% of Gen Z and Millennial buyers believe their purchases are indicative of their personalities and concerns.)

                                Explaining your mission gives your target audience another reason to align themselves with you. Give them your “big why.”

                            </p>
                        </div>
                    )}
                    {selectedSetting === "help" && (
                        <div>
                            <h3>Help</h3>
                            <p>
                                The number of lines in a paragraph depends on the size of the browser window. If you resize the browser window, the number of lines in this paragraph will change.
                            </p>
                            <p>Why does your business exist? Why does it matter? What’s the ultimate problem in your industry you’re trying to solve?

                                Ultimately, this is your mission, and you should share it on your About Us page. And, if you’re like many businesses that haven’t clearly defined theirs, now is the time.

                                Why is stating your mission important?</p><p>

                                Because a majority of modern consumers – especially Gen Z – want to know that their purchases align with who they are and that the businesses to which they give their dollars care about the things they care about. (Market research from Alter Agents found that 68% of Gen Z and Millennial buyers believe their purchases are indicative of their personalities and concerns.)

                                Explaining your mission gives your target audience another reason to align themselves with you. Give them your “big why.”

                            </p>
                        </div>
                    )}
                    {selectedSetting === "contact" && (
                        <div>
                            <h3>Contact Us</h3>
                            <p>
                                The number of lines in a paragraph depends on the size of the browser window. If you resize the browser window, the number of lines in this paragraph will change.
                            </p>
                            <p>Why does your business exist? Why does it matter? What’s the ultimate problem in your industry you’re trying to solve?

                                Ultimately, this is your mission, and you should share it on your About Us page. And, if you’re like many businesses that haven’t clearly defined theirs, now is the time.

                                Why is stating your mission important?</p><p>

                                Because a majority of modern consumers – especially Gen Z – want to know that their purchases align with who they are and that the businesses to which they give their dollars care about the things they care about. (Market research from Alter Agents found that 68% of Gen Z and Millennial buyers believe their purchases are indicative of their personalities and concerns.)

                                Explaining your mission gives your target audience another reason to align themselves with you. Give them your “big why.”

                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Setting;
