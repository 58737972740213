


import React, { useState } from 'react';
import { Card, CardContent, TextField, Grid, Typography, Button, Snackbar, Alert, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { Close as CloseIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { API_URLS } from '../utils/config';
import FormLabel from '../common/FormLabel';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: '#463636',
    opacity: '60%',
    borderRadius: '2rem',
    padding: "20px",
    margin: "20px",
    flexGrow: 1,
    alignItems: "center"
  },
  formContainer1: {
    backgroundColor: '#FEF0E7',
    marginTop: "1%",
    padding: "40px",
    margin: "40px",
    // borderRadius: '5rem',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: "30px",
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: "auto",
      marginRight: "auto",
      padding: "20px",
      margin: "20px",
    }
  },
  file: {
    marginTop: "15px ",
    marginBottom: "15px"
  },
  textField: {
    marginTop: "8px !important",
    marginBottom: '18px !important',
    width: '100%',
  },
  input: {
    color: 'black',
    backgroundColor: 'white',
    border: '2px solid #FF6B09',
    borderRadius: '4px',
    height: '35px',
  },
  button: {
    borderRadius: '0.3rem',
    alignItems: 'center',
    background: '#FF6B09 !important',
    justifyContent: 'center',
    color: 'white',
    marginTop: "10px"
  },
  errorText: {
    color: 'red',
  },
}));

const AddHotel = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [hotelData, setHotelData] = useState({
    name: '',
    description: '',
    area: '',
    city: '',
    openingTime: '',
    closingTime: '',
    latitude: '',
    longitude: '',
    username: '',
    password: '',
    image: null,
    logo: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHotelData({ ...hotelData, [name]: value });
  };

  const handleBlur = (name) => {
    if (!hotelData[name]) {
      setErrors({ ...errors, [name]: 'required*' });
    }
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setHotelData({ ...hotelData, image: file });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setHotelData({ ...hotelData, logo: file });
  };

  const buttonClicked = () => {
    navigate('/hotel-details');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      let formValid = true;
      const newErrors = {};
      setSubmitted(true);
      setLoading(true);

      Object.keys(hotelData).forEach(name => {
        const value = hotelData[name];
        if (!value && name !== 'latitude' && name !== 'longitude') {
          newErrors[name] = 'required*';
          formValid = false;
        } else {
          if (name === 'name' && !/^[a-zA-Z\s]*$/.test(value)) {
            newErrors[name] = 'Name should only contain letters and spaces';
            formValid = false;
          } else if (name === 'username' && !/\S+@\S+\.\S+/.test(value)) {
            newErrors[name] = 'Email is invalid';
            formValid = false;
          }
          if (!hotelData.latitude) {
            setHotelData({ ...hotelData, latitude: '' });
            formValid = true;
          }
          if (!hotelData.longitude) {
            setHotelData({ ...hotelData, longitude: '' });
            formValid = true;
          }
        }
      });

      if (!formValid) {
        setErrors(newErrors);
        return;
      }

      const formData = new FormData();
      formData.append('images', hotelData.image);
      formData.append('logo', hotelData.logo);
      const hotel = {
        name: hotelData.name,
        description: hotelData.description,
        address: hotelData.area + "," + hotelData.city,
        openingTime: formatTime(hotelData.openingTime),
        closingTime: formatTime(hotelData.closingTime),
        latitude: hotelData.latitude,
        longitude: hotelData.longitude,
        username: hotelData.username,
        password: hotelData.password,
      };
      const token = localStorage.getItem('token');

      const offerBlob = new Blob([JSON.stringify(hotel)], { type: 'application/json' });
      formData.append('hotel', offerBlob)
      const response = await fetch(`${API_URLS.BASE_URL}/add-hotel`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        setOpen(true);
        setErrors(data.message);
      }
      if (response.ok) {
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate('/hotel-details');
        }, 1500);
      }
    } catch (error) {
      setOpen(true);
      setErrors('Error adding hotel details');
      console.error('Error adding hotel details:', error);
    } finally {
      setLoading(false);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <ArrowBackIcon variant="button" onClick={buttonClicked} style={{ color: "white", marginLeft: "15px", marginTop: "10px", cursor: "pointer" }} />
        <Typography variant="h5" gutterBottom sx={{ color: "white", marginTop: isSmallScreen ? "2%" : "0.5%", marginLeft: isSmallScreen ? "30%" : "43%" ,fontSize:"2rem"}}>Add Hotel</Typography>
        <Grid item xs={12} sm={10} style={{display:'flex', justifyContent:'center', alignItems:'center'}} >
          <Card className={classes.formContainer1} sx={{borderRadius:'2rem' ,marginLeft: isSmallScreen ? "0px" : "25%"}}>
            <CardContent>
              <FormLabel label="Name" isRequired={true} /><br />
              <TextField
                name="name"
                value={hotelData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.name}
                helperText={<span className={classes.errorText}>{errors.name}</span>}
              />
              <FormLabel label="Description" isRequired={true} /><br />
              <TextField
                name="description"
                value={hotelData.description}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.description}
                helperText={<span className={classes.errorText}>{errors.description}</span>}
              />
              <FormLabel label="Area" isRequired={true} /><br />
              <TextField
                name="area"
                value={hotelData.area}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.area}
                helperText={<span className={classes.errorText}>{errors.area}</span>}
              />
              <FormLabel label="City" isRequired={true} /><br />
              <TextField
                name="city"
                value={hotelData.city}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.city}
                helperText={<span className={classes.errorText}>{errors.city}</span>}
              />
              <FormLabel label="Opening Time" isRequired={true} /><br />
              <input
                type="time"
                id="openingTime"
                name="openingTime"
                value={hotelData.openingTime}
                onChange={handleChange}
                style={{ width: "100%", marginBottom: "10px", marginTop: "10px", height: "30px" }}
              />{(submitted && !hotelData.openingTime) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "10px" }}>required*</p>}<br /><br />
              <FormLabel label="Closing Time" isRequired={true} /><br />
              <input
                type="time"
                id="closingTime"
                style={{ width: "100%", marginBottom: "10px", marginTop: "10px", height: "30px" }}
                name="closingTime"
                value={hotelData.closingTime}
                onChange={handleChange}
              />{(submitted && !hotelData.closingTime) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "10px" }}>required*</p>}<br /><br />
              <FormLabel label="Latitude" isRequired={false} /><br />
              <TextField
                name="latitude"
                value={hotelData.latitude}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <FormLabel label="Longitude" isRequired={false} /><br />
              <TextField
                name="longitude"
                value={hotelData.longitude}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <FormLabel label="Hotel Owner Email" isRequired={true} /><br />
              <TextField
                name="username"
                value={hotelData.username}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.username}
                helperText={<span className={classes.errorText}>{errors.username}</span>}
              />
              <FormLabel label="Password" isRequired={true} /><br />
              <TextField
                name="password"
                value={hotelData.password}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.password}
                helperText={<span className={classes.errorText}>{errors.password}</span>}
              />
              <FormLabel label="Hotel Image" isRequired={true} /><br />
              <input type="file" accept="image/*" onChange={handleImageChange} className={classes.file} /><br />
              {(submitted && !hotelData.image) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "10px" }}>required*</p>}<br />
              <FormLabel label="Hotel Logo" isRequired={true} /><br />
              <input type="file" accept="image/*" onChange={handleLogoChange} className={classes.file} /><br />
              {(submitted && !hotelData.logo) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "10px" }}>required*</p>}<br />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.button}
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        message='login successfull'
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Hotel Added Successfully.
        </Alert>
      </Snackbar>

      {errors && (
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message={errors.snackbar || ''}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errors}
          </Alert>
        </Snackbar>
      )}

    </div>
  );
};

export default AddHotel;
