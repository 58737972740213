import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardContent,
  Alert,
  Snackbar,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FormLabel from "../common/FormLabel";
import { API_URLS } from "../utils/config";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  root: {
    display: "flex",
    background: "#463636 !important",
    opacity: "60%",
    borderRadius: "2rem",
    justifyContent: "center",
    alignItems: "center",
  },
  customerFileBtn: {
    width: "600px",
    marginTop: "2%",
    '& input[type="file"]': {
      display: "none",
    },
    "& label": {
      padding: "6px 12px",
      backgroundColor: "#FFFFFF",
      color: "#463636",
      borderRadius: "4px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      borderStyle: "groove",
      border: "2px solid #FF6B09",
    },
    "& label:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  formContainer1: {
    backgroundColor: "#FFFF !important",
    width: "700px",
    padding: "16px",
    borderRadius: "2rem !important",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    overflowY: "auto !important",
    marginBottom: "30px",
    height: "80vh !important",
  },
  textField: {
    marginBottom: "20px",
  },
  input: {
    backgroundColor: "white",
    border: "2px solid #FF6B09 !important",
    borderRadius: "4px",
    height: "35px",
  },
  button: {
    borderRadius: "0.3rem",
    background: "#FF6B09 !important",
    marginLeft: "42% !important",
    color: "white",
  },
  iconButton: {
    top: -5,
    left: 630,
    backgroundColor: "white",
    color: "#FF6B09",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  loader: {
    margin: "auto",
    marginTop: "30%",
    width: "50px",
    aspectRatio: "1",
    borderRadius: "50%",
    border: "8px solid #0000",
    borderRightColor: "#ffa50097",
    position: "relative",
    animation: "$l24 1s infinite linear",
  },
  "@keyframes l24": {
    "100%": { transform: "rotate(1turn)" },
  },
  "@global": {
    ".loader::before, .loader::after": {
      content: '""',
      position: "absolute",
      inset: "-8px",
      borderRadius: "50%",
      border: "inherit",
      animation: "inherit",
      animationDuration: "2s",
    },
    ".loader::after": {
      animationDuration: "4s",
    },
  },
});

const HotelForm = ({ close }) => {
  const classes = useStyles();
  const [restaurantData, setRestaurantData] = useState(null);
  const [imageData, setImageData] = useState([]);
  const [openSnackBarNew, setOpenSnackBarNew] = useState(false);
  const [selectedAddImage, setSelectedAddImage] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    description: "",
    openingTime: "",
    closingTime: "",
    image: [],
  });

  const handleClose = () => setOpenSnackBarNew(false);

  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        const token = localStorage.getItem("token");
        const id = localStorage.getItem("hotelId");
        const response = await fetch(`${API_URLS.BASE_URL}/items/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch restaurant data");
        }
        const data = await response.json();
        setRestaurantData(data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchRestaurantData();
  }, []);

  useEffect(() => {
    if (restaurantData) {
      const initialImageData = restaurantData.image.map((imageData) => ({
        id: imageData.id,
        replaced: false,
      }));
      setImageData(initialImageData);
      setFormData({
        description: restaurantData.description,
        openingTime: restaurantData.openingTime,
        closingTime: restaurantData.closingTime,
        image: restaurantData.image || [],
      });
    }
  }, [restaurantData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e, index) => {
    const tempFormData = { ...formData };
    tempFormData["image"][index] = {
      ...tempFormData["image"][index],
      image: e.target.files[0],
      isChanged: true,
    };
    const newImageData = [...imageData];
    newImageData[index] = { id: 0, image: e.target.files[0], replaced: true };
    setImageData(newImageData);
    setFormData(tempFormData);
  };

  const handleAddImage = () => {
    if (formData.image.length < 10) {
      setSelectedAddImage(true);
      setFormData({
        ...formData,
        image: [...formData.image, { id: 0, image: null }],
      });
    }
  };

  const handleRemoveImage = (index) => {
    const newImages = formData.image.filter((_, i) => i !== index);
    setFormData({ ...formData, image: newImages });
    const imageId = formData.image[index].id;
    if (imageId) {
      handleDeleteConfirmation(imageId);
    }
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:00`;
  };

  const handleDeleteConfirmation = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_URLS.BASE_URL}/hotel-image/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to delete item");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.description)
      formErrors.description = "Description is required";
    if (!formData.openingTime)
      formErrors.openingTime = "Opening Time is required";
    if (!formData.closingTime)
      formErrors.closingTime = "Closing Time is required";
    if (formData.image.length === 0)
      formErrors.image = "At least one image is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const { description, openingTime, closingTime, image } = formData;
    const id = localStorage.getItem("hotelId");
    const data = new FormData();
    const formattedOpeningTime = openingTime ? formatTime(openingTime) : null;
    const formattedClosingTime = closingTime ? formatTime(closingTime) : null;
    const offerBlob = new Blob(
      [
        JSON.stringify({
          description,
          openingTime: formattedOpeningTime,
          closingTime: formattedClosingTime,
          isReplacedImage: formData.image
            .filter((data) => data.id && data.isChanged)
            .map((data) => data.id),
        }),
      ],
      { type: "application/json" }
    );
    data.append("hotel", offerBlob);

    formData.image
      .filter((data) => data.id === 0 || data.isChanged)
      .forEach((image) => {
        data.append("image", image.image, image.image.name);
      });

    const token = localStorage.getItem("token");
    fetch(`${API_URLS.BASE_URL}/update-hotel/${id}`, {
      method: "PUT",
      body: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((data) => {
      console.log("Success:", data);

      setOpenSnackBarNew(true);
      console.log(openSnackBarNew);
      setTimeout(() => {
        close();
      }, 1500);
    });
  };
  if (loading) {
    return <div className={classes.loader}></div>;
  }

  const closeHandle = () => {
    close();
  };

  return (
    <div style={{ height: "auto" }}>
      <Card className={classes.formContainer1}>
        <IconButton className={classes.iconButton} onClick={closeHandle}>
          <CloseIcon />
        </IconButton>
        <CardContent>
          <h2 style={{ marginLeft: "200px" }}>Update Hotel Details</h2>
          <form onSubmit={handleSubmit}>
            <FormLabel label="Description" isRequired={true} />
            <br />
            <TextField
              className={classes.textField}
              variant="outlined"
              fullWidth
              InputProps={{ className: classes.input }}
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              style={{ marginBottom: "10px", marginTop: "10px" }}
            />
            {errors.description && (
              <Typography className={classes.errorText}>
                {errors.description}
              </Typography>
            )}
            <br />
            <FormLabel label="Opening Time" isRequired={true} />
            <br />
            <input
              type="time"
              id="openingTime"
              name="openingTime"
              value={formData.openingTime || ""}
              onChange={(e) =>
                setFormData({ ...formData, openingTime: e.target.value })
              }
              style={{
                width: "100%",
                marginBottom: "10px",
                marginTop: "10px",
                height: "30px",
              }}
            />
            {errors.openingTime && (
              <Typography className={classes.errorText}>
                {errors.openingTime}
              </Typography>
            )}
            <br />
            <FormLabel label="Closing Time" isRequired={true} />
            <br />
            <input
              type="time"
              id="closingTime"
              name="closingTime"
              value={formData.closingTime || ""}
              onChange={(e) =>
                setFormData({ ...formData, closingTime: e.target.value })
              }
              style={{
                width: "100%",
                marginBottom: "10px",
                marginTop: "10px",
                height: "30px",
              }}
            />
            {errors.closingTime && (
              <Typography className={classes.errorText}>
                {errors.closingTime}
              </Typography>
            )}
            <br />
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "bold",
                color: "#463636",
                marginBottom: "10px",
              }}
            >
              Images{" "}
              {formData.image.length < 10 && (
                <Typography
                  variant="contained"
                  color="primary"
                  onClick={handleAddImage}
                  style={{
                    float: "right",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                >
                  + Add Image
                </Typography>
              )}
            </Typography>
            {formData.image.map((images, index) => (
              <div
                key={index}
                className={classes.customerFileBtn}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginTop: "20px",
                  width: "auto",
                }}
              >
                <label
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    width: "700px",
                  }}
                >
                  {images.image ? "Replace Image" : "Upload Image"}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, index)}
                  />
                </label>
                <DeleteOutlineIcon
                  variant="contained"
                  onClick={() => handleRemoveImage(index)}
                  style={{
                    marginLeft: "10px",
                    marginTop: "-10px",
                    float: "right",
                    cursor: "pointer",
                  }}
                >
                  Remove
                </DeleteOutlineIcon>
              </div>
            ))}
            {errors.image && (
              <Typography className={classes.errorText}>
                {errors.image}
              </Typography>
            )}
            <br />
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
            >
              Update
            </Button>
          </form>
        </CardContent>
      </Card>
      <Snackbar
        open={openSnackBarNew}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Updated successfully.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HotelForm;
