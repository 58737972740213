

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, TextField, Typography, Container, Grid, Snackbar, IconButton, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { API_URLS } from '../utils/config';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    background: '#463636',
    opacity: '60%',
    borderRadius: '2rem',
    padding: "20px",
    margin: "20px",
    flexGrow: 1,
    height: "auto",
    overflowY:"auto"

  },
  formContainer1: {
    backgroundColor: '#FEF0E7',
    margin: "30px",
    borderRadius: '2rem',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: "30px",
    maxWidth: '600px',
    marginTop: "13%"
  },
  textField: {
    marginTop: "8px !important",
    marginBottom: '18px !important',
    width: '100%',
  },
  input: {
    color: 'black',
    backgroundColor: 'white',
    border: '2px solid #FF6B09',
    borderRadius: '4px',
    height: '35px',
  },
  button: {
    borderRadius: '0.3rem',
    background: '#FF6B09 !important',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px!important',
    color: 'white !important',
   
  },
  errorText: {
    color: 'red',
    fontSize: '0.75rem',
    marginLeft: '15px',
  },
  categoryList: {
    marginTop: '40px',
   
    marginLeft:"35px"
  },
  addButton: {
    backgroundColor: '#FF6B09',
    color: 'white',
    height: '57px',
    width: '139px',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
},
  categoryItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    marginBottom: '10px',
  },
  categoryButtons: {
    display: 'flex',
    gap: '10px',
  },
  table: {
    width:"100px ",
    
  },
  tableCell: {
    color: 'black',
  },
  loader: {
    margin: "auto",
    width: "50px",
    aspectRatio: "1",
    borderRadius: "50%",
    border: "8px solid #0000",
    borderRightColor: "#ffa50097",
    position: "relative",
    animation: "$l24 1s infinite linear",
  },
  '@keyframes l24': {
    '100%': { transform: 'rotate(1turn)' }
  },
  '@global': {
    '.loader::before, .loader::after': {
      content: '""',
      position: 'absolute',
      inset: '-8px',
      borderRadius: '50%',
      border: 'inherit',
      animation: 'inherit',
      animationDuration: '2s'
    },
    '.loader::after': {
      animationDuration: '4s'
    }
  }
});

const OfferSubType = () => {
  const classes = useStyles();
  const [name, setName] = useState(''); 
  const [offerSubtypeList, setOfferSubtypeList] = useState([]);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
 

  const buttonClicked = () => {
    navigate('/hotel-details');
  };

  useEffect(() => {
    const fetchOfferSubTypeList = () => {
      const token = localStorage.getItem('token');
      fetch(`${API_URLS.BASE_URL}/offer-sub-type-list`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setOfferSubtypeList(data.data);
          setLoading(false);

        })
        .catch((error) => {
          setError(error.message);
        });
    };

    fetchOfferSubTypeList();

  }, []);

 
  const deleteCategory = async (id) => {
    try {
      const token = localStorage.getItem('token');

      const response = await fetch(`${API_URLS.BASE_URL}/delete-offer-sub-type/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        setOpen(true);
        setErrors({ snackbar: 'Failed to delete category' });
      } else {
        setOfferSubtypeList(offerSubtypeList.filter((category) => category.id !== id));
      }
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  if (loading) {

    return <div className={classes.loader}></div>
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <ArrowBackIcon variant="button" onClick={buttonClicked} style={{ color: "white",cursor:"pointer" }}> </ArrowBackIcon>
         
        </Grid>
        <Grid item xs={12} sm={10}>
  

        <h2 style={{ marginLeft:"300px", color:"white",width:"500px" }}>Offer Sub Type</h2>

          <TableContainer component={Paper} className={classes.offerSubtypeList} sx={{width:"600px",marginBottom:"20px",marginLeft:"50px"}}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}><b>Category Name</b></TableCell>
                  <TableCell align="right" className={classes.tableCell}><b>Actions</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offerSubtypeList.map((category) => (
                  <TableRow key={category.id}>
                    <TableCell component="th" scope="row" className={classes.tableCell}>
                      {category.name}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                     
                      <DeleteOutlineIcon  onClick={() => deleteCategory(category.id)}>
                      
                      </DeleteOutlineIcon>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            action={action}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {errors.snackbar ? (
              <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
                {errors.snackbar}
              </Alert>
            ) : (
              <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
                Category Added Successfully.
              </Alert>
            )}
          </Snackbar>
        </Grid>
      </Grid>
    </div>
  );
};

export default OfferSubType;
