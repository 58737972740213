import React, { useState } from 'react';
import { Box } from '@mui/material'; // Import Box from Material-UI
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { brown } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { db } from "../components/firebase";

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#FCB98E',
  },
  cardContainer: {
    borderRadius: '45px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    width: '80%',
    height: 'auto',
    maxWidth: '600px',
    backgroundColor: '#FEF0E7',
    position: 'relative',
    padding: '20px',
    boxSizing: 'border-box',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    backgroundColor: '#FEF0E7', // Match the form background color
    padding: '10px 0',
    zIndex: 1, // Ensure it stays above other content
  },
  formField: {
    marginBottom: '20px',
    height: '8vh',
  },
  closeButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px', // Added margin
  },
  sendButton: {
    marginTop: 'auto', // Move button to the bottom
    width: '100%',
  },
  messageField: {
    backgroundColor: '#ffffff',
    marginBottom: '20px',
    height: 'auto',
  },
  extraFields: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
  },
}));

const theme = createTheme({
  palette: {
    primary: brown,
  },
});

const InquiryForm = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [isHotelOwner, setIsHotelOwner] = useState('no');
  const [hotelName, setHotelName] = useState('');
  const [hotelAddress, setHotelAddress] = useState('');
  const [serviceNumber, setServiceNumber] = useState('');
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!username || !mobile || !address || !email || !message || (isHotelOwner === 'yes' && (!hotelName || !hotelAddress))) {
      setError('Please fill in all the fields.');
      setOpen(true);
      return;
    }

    try {
      await db.collection('inquiry').add({
        name: username,
        contact: mobile,
        email: email,
        address: address,
        message: message,
        hotelname: isHotelOwner === 'yes' ? hotelName : '',
        hoteladdress: isHotelOwner === 'yes' ? hotelAddress : '',
        servicenumber: serviceNumber,
        InquiryDate: new Date().toLocaleDateString(),
      });

      setMessage('Message sent successfully.');
      setOpen(true);
      setUsername('');
      setMobile('');
      setEmail('');
      setAddress('');
      setMessage('');
      setHotelName('');
      setHotelAddress('');
      setServiceNumber('');
      setIsHotelOwner('no');
      navigate('/login');
    } catch (error) {
      setError('An error occurred. Please try again.');
      setOpen(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.cardContainer}>
          <Box className={classes.closeButton} onClick={() => navigate('/login')}>
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Box>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Inquiry Form
            </Typography>
          <Box className={classes.formContainer} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <TextField
              label="Your name*"
              variant="standard"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={classes.formField}
            />
            <TextField
              label="Your mobile number*"
              variant="standard"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className={classes.formField}
            />
            <TextField
              label="Your email*"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.formField}
            />
            <TextField
              label="Your address*"
              variant="standard"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className={classes.formField}
            />
            <TextField
              label="Messages"
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={4}
              className={classes.messageField}
            />
            <FormControl component="fieldset" className={classes.formField}>
              <FormLabel component="legend">Are you a hotel owner?</FormLabel>
              <RadioGroup
                row
                value={isHotelOwner}
                onChange={(e) => setIsHotelOwner(e.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {isHotelOwner === 'yes' && (
              <div className={classes.extraFields}>
                <TextField
                  label="Hotel Name*"
                  variant="standard"
                  value={hotelName}
                  onChange={(e) => setHotelName(e.target.value)}
                  className={classes.formField}
                />
                <TextField
                  label="Hotel Address*"
                  variant="standard"
                  value={hotelAddress}
                  onChange={(e) => setHotelAddress(e.target.value)}
                  className={classes.formField}
                />
                <TextField
                  label="Service Number (optional)"
                  variant="standard"
                  value={serviceNumber}
                  onChange={(e) => setServiceNumber(e.target.value)}
                  className={classes.formField}
                />
              </div>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.sendButton}
          >
            Send Message
          </Button>
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity={error ? 'error' : 'success'} sx={{ width: '100%' }}>
            {error || message}
          </Alert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
};

export default InquiryForm;
