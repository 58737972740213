import React from 'react';

const FormLabel = ({ label, isRequired }) => {
  return (
    <label style={{ fontWeight: 'bold', color: '#463636', marginBottom: '10px' , marginTop: '10px !iportant'}}>
      {label} {isRequired ? <span style={{ color: 'red' }}>*</span> : <span style={{ color: '#aaa' }}>(Optional)</span>}
    </label>
  );
};

export default FormLabel;
