


import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography, Grid, CardMedia, Button } from '@mui/material';
import { API_URLS } from '../utils/config';

const useStyles = makeStyles({
  card: {
    flexDirection: 'row',
    marginRight: '1%',
  },
});

const formatDate = () => {
  const date = new Date();
  const day = date.getDate();

  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const OfferCardComponent = ({ offer }) => {
  const classes = useStyles();
  const [admin, setAdmin] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const isAdmin = localStorage.getItem('isAdmin');
    console.log(isAdmin)
    if (isAdmin === "true") {
      setAdmin(true);
    }
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      console.log(offer)
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_URLS.BASE_URL}/offer/image/${offer.offer.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },});
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setImageSrc(imageUrl);
      } catch (error) {
        console.error("Failed to fetch image", error);
      }
    };

    fetchImage();
  }, [offer.id]);

  const formatCreatedDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options); 
  };

  return (
    <Card className={classes.card} sx={{ width: '100%', height: 'auto', backgroundColor: '#CDBDB7', borderRadius: "7%" }}>
      <CardContent sx={{ padding: '0%', paddingBottom: '0%' }}>

        {offer.status !== "ACCEPTED" && (
          <div style={{ float: "right", backgroundColor: "#463636", color: "white", width: "30%", padding: "1%" }}>
            {offer.status === "NOTRESPONDED" && (
              <Typography variant="body1" style={{ textAlign: "center" }}>Pending</Typography>
            )}
            {offer.status === "REJECTED" && (
              <Typography variant="body1" style={{ textAlign: "center" }}>Declined</Typography>
            )}
          </div>
        )}

        <Typography variant="h5" sx={{ color: '#463636', padding: '3%' }}><b>{offer.user.userName}</b></Typography>
        <Typography variant="body2" sx={{ color: '#463636', paddingLeft: '3%' }}><b>Created date : {formatCreatedDate(offer.offer.createdDate)}</b></Typography>
        {admin && <Typography variant="h7" sx={{ color: '#463636', paddingLeft: '3%' }}><b>Created by : {offer.offer.hotel.name}</b></Typography>}

        <Grid container sx={{ backgroundColor: '#FF6B09', height: 'auto', marginTop: '3%', width: 'auto' }}>
          <Grid item xs={6} sx={{ color: 'white', padding: '3%' }}>
            <Typography variant="body1" sx={{
              display: "-webkit-box",
              maxWidth: "200px",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden"
            }}><b>{offer.offer.title}</b></Typography>
            <Typography variant="body2"><b>{offer.offer.subTypeName}</b></Typography>

          </Grid>
          <Grid item xs={6} sx={{ top: '-10px', position: 'relative' }}>
            <CardMedia
              component="img"
              sx={{
                width: '150px', height: '100px',
                objectFit: 'cover',
                borderRadius: '50%',
                transform: 'rotate(343deg)',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
              }}
              image={imageSrc}
              alt=" Uploaded Image"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} >
          <Grid item xs={8} sx={{ position: 'relative' }}>
            <Button variant="contained" sx={{ backgroundColor: '#463636', width: '75%', pointerEvents: 'none', marginTop: "5%", marginLeft: "5%" }}>
              {offer.offer.offerTypeId.name}
            </Button>
            <Grid item xs={4} sx={{ position: 'absolute', top: '-10px', right: '4%', marginTop: "5%" }}>
              <CardMedia
                component="img"
                sx={{ width: '100%', height: '100%' }}
                image='./newFunLogo.png'
                alt="Uploaded Image"
              />
            </Grid>


          </Grid>
          <Grid item xs={4} sx={{ color: '#463636' }} >
            <Typography variant="body2" sx={{ marginTop: "20%" }}><b>{formatDate()}</b></Typography>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

export default OfferCardComponent;
