


import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

const EditHotelDialog = ({ open, handleClose, hotelData, handleSave }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [nameError, setNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);

  useEffect(() => {
    if (open) {
      setName(hotelData.name);
      setAddress(hotelData.address);
      setNameError(false);
      setAddressError(false);
    }
  }, [open, hotelData]);

  const handleSaveClick = () => {
    if (name.trim() === '') {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (address.trim() === '') {
      setAddressError(true);
    } else {
      setAddressError(false);
    }

    if (name.trim() !== '' && address.trim() !== '') {
      handleSave({ id: hotelData.id, name, address });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Hotel</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Hotel Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={nameError}
          helperText={nameError ? 'Hotel name is required' : ''}
        />
        <TextField
          margin="dense"
          label="Address"
          type="text"
          fullWidth
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          error={addressError}
          helperText={addressError ? 'Address is required' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveClick} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditHotelDialog;
