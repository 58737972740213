import React, { useState } from 'react';
import { Button, TextField, Typography, Card, CardContent, Checkbox, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { API_URLS } from '../utils/config';
import FormLabel from '../common/FormLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: '#463636',
    opacity: '60%',
    borderRadius: '2rem',
    padding: "20px",
    margin: "20px",
    flexGrow: 1,
    height: "auto",
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: "white",
    cursor: "pointer",
  },
  customerFileBtn: {
    width: "auto",
    marginTop: "4%",
    justifyContent:'center',
    '& input[type="file"]': {
      display: 'none'
    },
    '& label': {
      padding: "6px 12px",
      backgroundColor: "#FFFFFF",
      color: "#463636",
      height: "20px",
      borderRadius: "4px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      borderStyle: "groove",
      border: '2px solid #FF6B09',
    },
    '& label:hover': {
      backgroundColor: "#FFFFFF"
    }
  },
  formContainer1: {
    backgroundColor: '#FEF0E7',
    padding: "40px",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: "30px",
    maxWidth: '600px',
    position: 'relative', // Ensure the container is positioned relatively
    [theme.breakpoints.down('sm')]: {
      padding: "20px",
      maxWidth: '90%',
    },
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    width: '100%', // Ensure the field container takes full width
  },
  textField: {
    marginTop: "8px !important",
    marginBottom: '18px !important',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '4px',
    height: '35px',
    color: 'black'
  },
  button: {
    borderRadius: '0.3rem',
    background: '#FF6B09 !important',
    display: 'flex',
    marginTop: '30px!important',
    color: 'white !important',
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  button2: {
    borderRadius: '0.3rem',
    background: '#FF6B09 !important',
    display: 'flex',
    justifyContent: 'center',
    color: 'white !important',
  },
  closeButton: {
    marginLeft: '8px',
    color: '#FF0000',
    float: "right",
    marginTop: "-10px !important",
    position: 'absolute', // Position absolutely to keep it fixed within the field container
    right: 0,
    top: 0,
  },
  errorText: {
    color: 'red',
    fontSize: '0.75rem',
    marginLeft: '15px',
  },
}));

const SubTypeCategory = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [subtypeName, setSubtypeName] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selectedAddImage, setSelectedAddImage] = useState(false);
  const [fields, setFields] = useState([{ display: '', item: '', includeItem: false, quantity: '', includeQuantity: false, type: 'text', displayShown: true }]);

  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...fields];
    updatedFields[index][key] = value;
    setFields(updatedFields);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      setSelectedFileName(file.name);
    }
  };

  const handleCheckboxChange = (index, key) => {
    const updatedFields = [...fields];
    updatedFields[index][key] = !fields[index][key];
    setFields(updatedFields);
  };

  const addField = () => {
    setFields([...fields, { display: '', item: '', includeItem: false, quantity: '', includeQuantity: false, type: 'text', displayShown: true }]);
  };

  const removeField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };
  
  const buttonClicked = () => {
    navigate('/hotel-details');
  }
  
  const handleSubmit = async () => {
    try {
      setSubmitted(true);
      if (!subtypeName && (!fields || !selectedFile)) {
        return;
      }
      const formData = new FormData();
      const offerJson = {
        subtypeName,
        fields: fields.map(field => ({
          display: field.display,
          ...(field.includeItem && { item: field.item }),
          ...(field.includeQuantity && { quantity: field.quantity }),
          type: field.type,
          displayShown: field.displayShown
        })),
      }
      const offer = new Blob([JSON.stringify(offerJson)], { type: 'application/json' });
      if (selectedFile) {
        formData.append('image', selectedFile);
      } else {
        const emptyBlob = new Blob([''], { type: 'image/png' });
        formData.append('image', emptyBlob);
      }
      formData.append('offer', offer);

      const token = localStorage.getItem('token');

      const response = await fetch(`${API_URLS.BASE_URL}/add-sub-type`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        setOpen(data.message);
      }
      if (response.ok) {
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate('/hotel-details');
        }, 1000);
      }
    } catch (error) {
      setOpen('Error adding subtype')
      console.error('Error adding subtype:', error);
    }
  };

  const handleOfferType = () => {
    navigate('/subtype-details');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <ArrowBackIcon
        className={classes.backButton}
        onClick={buttonClicked}
      />
      <div style={{ display: "flex", justifyContent: "end", width: "100%",position: 'fixed', marginBottom:"52.5%" , marginRight:"27%" }}>
        <button onClick={handleOfferType}>Show & delete Sub Type</button>
      </div>
      <Typography variant="h4" gutterBottom style={{ color: '#FFFFFF', marginTop: "20px", textAlign: isSmallScreen ? 'center' : 'left' }}>Add Sub Type</Typography>
      <Button className={classes.button2} variant="contained" color="primary" onClick={addField}>Add Field</Button><br />

      <Card className={classes.formContainer1} sx={{ marginTop: "0px !important", borderRadius:'2rem' }}>
        <CardContent style={{justifyContent:"center", alignItems: 'center'}}>
          <label style={{ fontWeight: 'bold', color: '#463636', marginBottom: "10px" }}>Sub Type Name <label style={{ color: "red" }}> * </label></label>
          <TextField
            variant="outlined"
            value={subtypeName}
            onChange={(e) => setSubtypeName(e.target.value)}
            fullWidth
            margin="normal"
            style={{ height: "5px !important", padding: "0px" }}
          />
          {(submitted && !subtypeName) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "10px" }}>required*</p>}
          <div className={classes.fieldContainer}>
            {fields.map((field, index) => (
              <div key={index} style={{ position: 'relative', width: '100%' }}> {/* Ensuring relative positioning */}
                <label style={{ fontWeight: 'bold', color: '#463636', marginBottom: "10px" }}>Fields <label style={{ color: "red" }}> * </label>  <IconButton className={classes.closeButton} onClick={() => removeField(index)}>
                  <CloseIcon />
                </IconButton></label><br />
                <TextField
                  style={{ height: "5px !important", padding: "0px", flexGrow: 1, marginTop: "0px" }}
                  variant="outlined"
                  value={field.display}
                  onChange={(e) => handleFieldChange(index, 'display', e.target.value)}
                  fullWidth
                  margin="normal"
                />
                {(submitted && !field.display) && <p style={{ color: '#d32f2f', fontSize: "0.75rem", marginLeft: "10px" }}>required*</p>}
                <br />
                <Checkbox
                  checked={field.includeItem}
                  onChange={() => handleCheckboxChange(index, 'includeItem')}
                />
                <span>Include Item<br /></span>
                <Checkbox
                  checked={field.includeQuantity}
                  onChange={() => handleCheckboxChange(index, 'includeQuantity')}
                />
                <span>Include Quantity</span>
                <div>
                  <Checkbox
                    checked={field.displayShown}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <span>Display Shown</span>
                </div>
              </div>
            ))}
          </div><br />
          <FormLabel label="Image" isRequired={false} /><br />
          <div className={`input-group ${classes.customerFileBtn}`}>
            <label style={{width: "100%", borderRadius: 10,padding:15, marginBottom: 15}} className="input-group-text" htmlFor="inputGroupFile">
              <span style={{ marginRight: "2%" }}>{selectedFileName ? selectedFileName : 'Upload Your Image'}</span>
              <input type="file" accept="image/*" onChange={handleFileChange} className="form-control" id="inputGroupFile" />
            </label>
            <Button style={{width: '40%',borderRadius: 5}} variant="contained" className={classes.button} onClick={handleSubmit}>Submit</Button>
          </div>
        </CardContent>
      </Card>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        message='Item added successfully'
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Sub-type Added Successfully.
        </Alert>
      </Snackbar>

      {errors && (
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message={errors.snackbar || ''}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errors}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default SubTypeCategory;
