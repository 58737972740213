

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, TextField, Typography, Container, Grid, Snackbar, IconButton, MenuItem, Select, Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormLabel from '../common/FormLabel';
import { API_URLS } from '../utils/config';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles({
  root: {
    background: '#463636',
    opacity: '60%',
    borderRadius: '2rem',
    padding: "20px",
    margin: "20px",
    flexGrow: 1,
    height: "93vh",
  },
  customerFileBtn: {
    width: "auto",
    marginTop: "2%",
    '& input[type="file"]': {
      display: 'none'
    },
    '& label': {
      padding: "6px 12px",
      backgroundColor: "#FFFFFF",
      color: "#463636",
      height: "20px",
      borderRadius: "4px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      borderStyle: "groove",
      border: '2px solid #FF6B09',
    },
    '& label:hover': {
      backgroundColor: "#FFFFFF"
    }
  },
  formContainer1: {
    backgroundColor: '#FEF0E7',
    padding: "40px",
    margin: "30px",
    borderRadius: '2rem',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: "30px",
    maxWidth: '600px',
  },
  textField: {
    marginTop: "8px !important",
    marginBottom: '18px !important',
    width: '100%',
  },
  input: {
    color: 'black',
    backgroundColor: 'white',
    border: '2px solid #FF6B09',
    borderRadius: '4px',
    height: '35px',
  },
  button: {
    borderRadius: '0.3rem',
    background: '#FF6B09 !important',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px!important',
    color: 'white !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
  },
  errorText: {
    color: 'red',
    fontSize: '0.75rem',
    marginLeft: '15px',
  },
});

const CategotyForm = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [itemData, setItemData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [isImageFieldClicked, setIsImageFieldClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const [fileError, setFileError] = useState('');
  const { itemId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB

    if (file.size > maxSize) {
      setFileError('File size should be less than or equal to 2MB.');
      return;
    }
    setSelectedFile(file);
    if (file) {
      setSelectedFileName(file.name);
    }
  };

  const handleButtonClick = () => {
    setIsImageFieldClicked(true);
  };

  const validateName = (value) => {
    const isValid = /^[a-zA-Z\s]*$/.test(value);
    return isValid ? '' : 'Only letters and spaces allowed';
  };

  const handleBlur = (field, value) => {
    if (!value) {
      setErrors({ ...errors, [field]: 'required*' });
    } else {
      switch (field) {
        case 'name':
          setErrors({ ...errors, [field]: validateName(value) });
          break;
        default:
          break;
      }
    }
  };

  const buttonClicked = () => {
    navigate('/hotel-details');
  };

  useEffect(() => {
    if (itemId) {
      const numericId = itemId.split('=')[1];
      fetchOfferTypeDetails(numericId);
    }
  }, [itemId]);

  useEffect(() => {
    if (Object.keys(itemData).length > 0 && categoryList.length > 0) {
      setName(itemData.name);
      setPrice(itemData.price);
      const categoryId = categoryList.find((item) => item.name === itemData.category).id;
      setSelectedCategory(categoryId || 0);
    }
  }, [itemData, categoryList]);

  const fetchOfferTypeDetails = (id) => {
    const token = localStorage.getItem('token');

    fetch(`${API_URLS.BASE_URL}/offer-type/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const data = response.json();
        if (!response.ok) {
          setOpen(true);
          setErrors(data.message);
        }
        return data;
      })
      .then((data) => {
        setOpenSnackbar(true);
        setItemData(data.data);
      })
      .catch((error) => {
        setErrors({ ...errors, snackbar: error.message });
      });
  };

  const handleUpload = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    setSubmitted(true);
    if (itemId) {
      updateItem();
    } else {
      addItem();
    }
  };

  const addItem = async () => {
    try {
      const formData = new FormData();

      const offerType = {
        name: name,
      };

      const offerBlob = new Blob([JSON.stringify(offerType)], { type: 'application/json' });

      if (!name) {
        setErrors({
          name: !name ? 'required*' : validateName(name),
        });
        return;
      }

      formData.append('image', selectedFile);
      formData.append('offerType', offerBlob);

      const token = localStorage.getItem('token');

      const response = await fetch(`${API_URLS.BASE_URL}/add-offer-type`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setIsSubmitting(false);
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate('/hotel-details');
        }, 1000);
      } else {
        setOpen(true);
        setErrors('Failed to add item');
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const updateItem = async () => {
    try {
      const formData = new FormData();

      const offerType = {
        name: name,
      };

      const offerBlob = new Blob([JSON.stringify(offerType)], { type: 'application/json' });

      if (selectedFile) {
        formData.append('image', selectedFile);
      } else {
        const emptyBlob = new Blob([''], { type: 'image/png' });
        formData.append('image', emptyBlob);
      }
      formData.append('item', offerBlob);

      const token = localStorage.getItem('token');
      const id = itemId.split('=')[1];

      const response = await fetch(`${API_URLS.BASE_URL}/update-offer-type/${id}`, {
        method: 'PUT',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        setOpen(true);
        setErrors('Failed to update item');
      } else {
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate('/profile');
        }, 1000);
      }
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleOfferType = () => {
    navigate('/offer/offer-type');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Container className={classes.root} >
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <ArrowBackIcon
            onClick={buttonClicked}
            style={{ color: "white", cursor: "pointer", margin: "10px" }}
          />
          <Typography variant="h4" color="white" style={{marginLeft:"10%"}} >
            Add Category
          </Typography>
          <button onClick={handleOfferType} >Show & delete Offer Type</button>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Card className={classes.formContainer1} style={{ width: isSmallScreen ? '100%' : '600px' , borderRadius:'20px' }}>
            <CardContent>
              <form>
                <FormLabel label="Image" isRequired={true} />
                <div className={`input-group ${classes.customerFileBtn}`}>
                  <label style={{width: "100%", height:30, borderRadius: 10, marginBottom: 15}} className="input-group-text" htmlFor="inputGroupFile">
                    <span style={{ marginRight: "2%" }}>{selectedFileName ? selectedFileName : 'Upload Your Image'}</span>
                    <input type="file" accept="image/*" onChange={handleFileChange} className="form-control" id="inputGroupFile" onClick={handleButtonClick} />
                  </label>
                  {(!selectedFileName && submitted) && <p className={classes.errorText}>required*</p>}
                  {fileError && <p className={classes.errorText}>{fileError}</p>}
                  <p style={{ fontSize: "14px" }}>Recommended size 512 x 512 px in JPG, GIF, or PNG format up to a maximum size of 2MB.</p>
                </div>
                <FormLabel label="Name" isRequired={true} />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => handleBlur('name', name)}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <div style={{textAlign:"center"}}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleUpload}
                  disabled={isSubmitting}
                >
                  <b>{itemId ? 'Update' : 'Add'}</b>
                </Button></div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Category Added Successfully.
        </Alert>
      </Snackbar>
      {errors && (
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message={errors.snackbar || ''}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errors}
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default CategotyForm;
