import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, TextField, Typography, Container, Grid, } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { API_URLS } from '../utils/config';
import FormLabel from '../common/FormLabel';

const windowHeight = window.innerHeight;

const useStyles = makeStyles({
  root: {
    display: 'flex',
   background: '#463636',
   // paddingTop: '30px',
   opacity: '60%',
   borderRadius: '2rem',
   padding:"20px",
   margin:"20px",
  // alignContent:"center",
  flexGrow: 1,
 
  
  
 },
 formContainer1: {
   backgroundColor: '#FEF0E7',
    padding:"40px",
    margin:"30px",
   borderRadius: '2rem',
   boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
   display: 'flex',
   flexDirection: 'column', 
   alignItems: 'center', 
   marginBottom:"30px",
   maxWidth: '600px',
  // margin: '0 auto',
   // width:"60%"
 },
 textField:{
   marginTop:"8px !important",
   marginBottom: '18px !important',
   width: '100%',
 },
  customerFileBtn: {
    width: "auto",
    marginTop: "2%",
    '& input[type="file"]': {
      display: 'none'

    },
    '& label': {
      padding: "6px 12px",
      backgroundColor: "#FFFFFF",
      color: "#463636",
      borderRadius: "4px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      borderStyle: "groove",
      border: '2px solid #FF6B09',

    },
    '& label:hover': {
      backgroundColor: "#FFFFFF"
    }

  },
  // formContainer1: {
  //   backgroundColor: '#FEF0E7',
  //   padding:"40px",
  //   margin:"60px",
  //  borderRadius: '2rem',
  //  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  //  display: 'flex',
  //  flexDirection: 'column', 
  //  alignItems: 'center', 
  //  marginBottom:"30px",
  //  maxWidth: '600px',
  // },
  // textField: {
  //   marginTop:"8px !important",
  //   marginBottom: '18px !important',
  //   width: '100%',
  // },
  input: {
    color: 'black',
    backgroundColor: 'white',
    border: '2px solid #FF6B09',
    borderRadius: '4px',
    height: '35px',
  },
  button: {
    borderRadius: '0.3rem',
    background: '#FF6B09 !important',

    marginTop: '30px!important',
    color: 'white !important',
 
  },
  errorText: {
    color: 'red',
    fontSize: '0.75rem',
    marginLeft: '15px',
  },
});

const AddItem = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [itemData, setItemData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [isImageFieldClicked, setIsImageFieldClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [fileError, setFileError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { itemId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  useEffect(() => {
    const fetchCategoryList = () => {
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('hotelId');


      fetch(`${API_URLS.BASE_URL}/category/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data.data)
          setCategoryList(data.data);
        })
        .catch((error) => {
          setErrors({ ...errors, ['snackbar']: error.message });
        });
    };

    fetchCategoryList();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const maxSize = 2 * 1024 * 1024; // 2MB

        if (file.size > maxSize) {
          setFileError('File size should be less than or equal to 2MB.');
          return;
        }
    setSelectedFile(file);
    if (file) {
      setSelectedFileName(file.name);
    }
  };

  const handleButtonClick = () => {
    setIsImageFieldClicked(true);
  };

  const validateName = (value) => {
    const isValid = /^[a-zA-Z\s]*$/.test(value);
    return isValid ? '' : 'Only letters and spaces allowed';
  };

  const validateCategory = (value) => {
    const isValid = /^[a-zA-Z\s]*$/.test(value);
    return isValid ? '' : 'Only letters and spaces allowed';
  };

  const validatePrice = (value) => {
    const isValid = !isNaN(value);
    return isValid ? '' : 'Price must be a number';
  };

  const handleBlur = (field, value) => {
    if (!value) {
      setErrors({ ...errors, [field]: 'required*' });
    } else {
      switch (field) {
        case 'name':
          setErrors({ ...errors, [field]: validateName(value) });
          break;
        case 'category':
          setErrors({ ...errors, [field]: validateCategory(value) });
          break;
        case 'price':
          setErrors({ ...errors, [field]: validatePrice(value) });
          break;
        default:
          break;
      }
    }
  };
  const buttonClicked = () => {
    navigate('/profile');
  }

  useEffect(() => {
    if (itemId) {
      const numericId = itemId.split('=')[1];
      fetchItemDetails(numericId);
    }
  }, [itemId]);

  useEffect(() => {
    if (Object.keys(itemData).length > 0 && categoryList.length > 0) {
      setName(itemData.name);
      setPrice(itemData.price);
      const categoryId = categoryList.find((item) => item.name == itemData.category).id;
      setSelectedCategory(categoryId || 0);
      
    }
  }, [itemData, categoryList]);

  const fetchItemDetails = (id) => {

    const token = localStorage.getItem('token');

    fetch(`${API_URLS.BASE_URL}/item/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.data)
        setItemData(data.data);

      })

      .catch((error) => {
        setErrors({ ...errors, ['snackbar']: error.message });
      });
  };

  const handleUpload = async () => {
    setIsSubmitting(true);
    // if (isSubmitting) {
    //   return;
    // }

    setSubmitted(true);
    if (itemId) {
      setIsSubmitting(false)
      updateItem();
    } else {
      setIsSubmitting(false)
      addItem();
    }
  };

  const addItem = async () => {
    try {
      const formData = new FormData();
      const id = localStorage.getItem("hotelId");
      console.log(selectedCategory);
      const item = {
        name: name,
        category: selectedCategory,
        price: price,
        hotelId: id
      }
      console.log(item)
      const offerBlob = new Blob([JSON.stringify(item)], { type: 'application/json' });

      if (!name || !price ) {
        setErrors({
          name: !name ? 'required*' : validateName(name),
          price: !price ? 'required*' : validatePrice(price),
        
        });
        return;
      }
      if(!selectedCategory || !selectedFile){
        return;
      }

      formData.append('image', selectedFile);
      formData.append('menuItem', offerBlob);

      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URLS.BASE_URL}/add-item`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            setIsSubmitting(false);
            setOpenSnackbar(true);
            setTimeout(() => {
                navigate('/profile');
            }, 1500);
        } else {
            const responseData = await response.json();
            setOpen(true);
            console.log(response);
            console.log(responseData);
            setErrors(responseData.message);
            setIsSubmitting(false);
        }
    } catch (error) {
        setIsSubmitting(false);
        console.error('Error:', error);
    }
  }catch (error) {
    setIsSubmitting(false);
    console.error('Error:', error);
}
  }

  const updateItem = async () => {
    try {
      
      const formData = new FormData();
      const hotelId = localStorage.getItem("hotelId");
      const item = {
        name: name,
        category: selectedCategory,
        price: price,
        hotelId: hotelId
      }
      if (!name || !price ) {
        setErrors({
          name: !name ? 'required*' : validateName(name),
          price: !price ? 'required*' : validatePrice(price),
        
        });
        return;
      }
      if(!selectedCategory ){
        setSubmitted(true);
        return;
      }
      const offerBlob = new Blob([JSON.stringify(item)], { type: 'application/json' });

      if (selectedFile) {
        formData.append('image', selectedFile);
      } else {
        const emptyBlob = new Blob([''], { type: 'image/png' });
        formData.append('image', emptyBlob);
      }
      formData.append('item', offerBlob);

      const token = localStorage.getItem('token');
      const id = itemId.split('=')[1];

      const response = await fetch(`${API_URLS.BASE_URL}/update-item/${id}`, {
        method: 'PUT',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setIsSubmitting(false);
        setOpenSnackbar(true);
        setTimeout(() => {
            navigate('/profile');
        }, 1500);
    } else {
        const responseData = await response.json();
        setOpen(true);
        console.log(response);
        console.log(responseData);
        setErrors(responseData.message);
        setIsSubmitting(false);
    }
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        
            <Grid item xs={12} sm={2} >
      <ArrowBackIcon variant="button"  onClick={buttonClicked} style={{color:"white",marginTop:"1px",cursor:"pointer"}} >  </ArrowBackIcon>
      </Grid>
      <Grid item xs={12} sm={10} style={{display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
      <div style={{ textAlign: "center", marginLeft: "-300px" }}>
            <h2 style={{ color: "white" }}>Add Menu</h2>
          </div>
        <Card className={classes.formContainer1} sx={{marginTop:"9%",marginBottom:"9%"}}>
          <CardContent>
            <form>   
            <FormLabel label="Image" isRequired={true} /><br />
                <div className={`input-group ${classes.customerFileBtn}`}>
                  <label style={{width: "100%", borderRadius: 10, marginBottom: 15}} className="input-group-text" htmlFor="inputGroupFile">
                    <span style={{ marginRight: "2%" }}>{selectedFileName ? selectedFileName : 'Upload Your Image'}</span>
                    <input type="file" accept="image/*" onChange={handleFileChange} className="form-control" id="inputGroupFile" onClick={handleButtonClick} />
                  </label>
                  { (!selectedFileName && submitted) && <p className={classes.errorText}>required*</p>}
                  {fileError && <p className={classes.errorText}>{fileError}</p>}
                  <p style={{ fontSize: "14px" }}>Recommended size 512 x 512 px in JPG, GIF, or PNG format up to a maximum size of 5MB.</p>
                </div>
              
                <FormLabel label="Name" isRequired={true} /><br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => handleBlur('name', name)}
                  error={!!errors.name}
                  helperText={errors.name}
                />
          
          <FormLabel label="Category" isRequired={true} /><br />
                <Select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} sx={{ width: "100%", height: "35px", borderColor: "#FF6B09",marginBottom:"18px",marginTop:"8px"}}>
                  {categoryList.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                {(!selectedCategory && submitted) && <p className={classes.errorText}>required*</p>}
                <FormLabel label="Price" isRequired={true} /><br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  onBlur={() => handleBlur('price', price)}
                  error={!!errors.price}
                  helperText={errors.price}
                />
            
           <div style={{textAlign:"center"}}>
                <Button variant="button" className={classes.button} onClick={handleUpload} disabled={isSubmitting}>
                <b>{itemId ? 'Update' : 'Add'}</b>
                </Button>
                </div>
             
            </form>
          </CardContent>
        </Card>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={handleClose}
          message='Item added successfull'
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
              <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
             Menu Added Successfully.
          </Alert>
          </Snackbar>
          
        {errors && (
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={errors.snackbar || ''}
            action={action}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
             <Alert
           onClose={handleClose}
           severity="error"
           variant="filled"
           sx={{ width: '100%' }}
         >
            {errors}
         </Alert>
         </Snackbar>
        )}
      
      </Grid>
      </Grid>
    </div>
  );
};

export default AddItem;

