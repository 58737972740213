import React, { useState, useEffect } from 'react';
import { db } from '../components/firebase'; // Adjust the import path as needed
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import brown from '@mui/material/colors/brown';
import { makeStyles } from '@mui/styles';
import Sidebar from '../components/sidebar'; // Adjust the import path as needed
import useMediaQuery from '@mui/material/useMediaQuery';

const theme = createTheme({
  palette: {
    primary: brown,
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    maxWidth: '1600px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  header: {
    display: 'flex',
    marginBottom: '20px',
    color: '#463636',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  tableContainer: {
    backgroundColor: '#FCB98E',
    borderRadius: '10px',
  },
  table: {
    minWidth: 650,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      overflowX: 'auto',
    },
  },
  tableHead: {
    backgroundColor: '#FF6B09',
    color: 'white',
  },
  tableCell: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

const InquiryHistory = () => {
  const [inquiries, setInquiries] = useState([]);
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const snapshot = await db.collection('inquiry').get();
        const inquiriesData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setInquiries(inquiriesData);
      } catch (error) {
        console.error('Error fetching inquiries:', error);
      }
    };

    fetchInquiries();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.container}>
            <Typography variant="h4" className={classes.header} gutterBottom>
              Inquiry History
            </Typography>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    <TableCell className={classes.tableCell}>Name</TableCell>
                    <TableCell className={classes.tableCell}>Contact</TableCell>
                    <TableCell className={classes.tableCell}>Email</TableCell>
                    <TableCell className={classes.tableCell}>Address</TableCell>
                    <TableCell className={classes.tableCell}>Message</TableCell>
                    <TableCell className={classes.tableCell}>Hotel Name</TableCell>
                    <TableCell className={classes.tableCell}>Hotel Address</TableCell>
                    <TableCell className={classes.tableCell}>Service Number</TableCell>
                    <TableCell className={classes.tableCell}>Inquiry Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inquiries.map((inquiry) => (
                    <TableRow key={inquiry.id} className={classes.tableRow}>
                      <TableCell>{inquiry.name}</TableCell>
                      <TableCell>{inquiry.contact}</TableCell>
                      <TableCell>{inquiry.email}</TableCell>
                      <TableCell>{inquiry.address}</TableCell>
                      <TableCell>{inquiry.message}</TableCell>
                      <TableCell>{inquiry.hotelname}</TableCell>
                      <TableCell>{inquiry.hoteladdress}</TableCell>
                      <TableCell>{inquiry.servicenumber}</TableCell>
                      <TableCell>{inquiry.InquiryDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </main>
      </div>
    </ThemeProvider>
  );
};

export default InquiryHistory;
