import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Container,
  Grid,
  Snackbar,
  IconButton,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { API_URLS } from "../utils/config";
import FormLabel from "../common/FormLabel";

const useStyles = makeStyles({
  root: {
    display: "flex",
    background: "#463636",
    opacity: "60%",
    borderRadius: "2rem",
    padding: "20px",
    margin: "20px",
    flexGrow: 1,
    height: "auto",
    overflowY: "auto",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  formContainer1: {
    backgroundColor: "#FEF0E7",
    margin: "30px",
    borderRadius: "2rem",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    marginLeft: "185px" ,
    alignItems: "center",
    // marginBottom: "30px",
    maxWidth: "600px",
    // marginTop: "13%"
  },
  textField: {
    marginTop: "8px !important",
    marginBottom: "18px !important",
    width: "60%",
  },
  input: {
    color: "black",
    backgroundColor: "white",
    border: "2px solid #FF6B09",
    borderRadius: "4px",
    height: "35px",
  },
  button: {
    borderRadius: "0.3rem",
    background: "#FF6B00 !important",
    marginTop: "10px!important",
    color: "white !important",
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  categoryList: {
    marginTop: "40px",

    marginLeft: "185px",
  },
  categoryItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginBottom: "10px",
  },
  categoryButtons: {
    display: "flex",
    gap: "10px",
  },
  table: {
    width: "100px ",
  },
  tableCell: {
    color: "black",
  },
  loader: {
    margin: "auto",
    width: "50px",
    aspectRatio: "1",
    borderRadius: "50%",
    border: "8px solid #0000",
    borderRightColor: "#ffa50097",
    position: "relative",
    animation: "$l24 1s infinite linear",
  },
  "@keyframes l24": {
    "100%": { transform: "rotate(1turn)" },
  },
  "@global": {
    ".loader::before, .loader::after": {
      content: '""',
      position: "absolute",
      inset: "-8px",
      borderRadius: "50%",
      border: "inherit",
      animation: "inherit",
      animationDuration: "2s",
    },
    ".loader::after": {
      animationDuration: "4s",
    },
  },
});

const ItemCategory = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = React.useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { itemId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  const validateName = (value) => {
    const isValid = /^[a-zA-Z\s]*$/.test(value);
    return isValid ? "" : "Only letters and spaces allowed";
  };

  const handleBlur = (field, value) => {
    if (!value) {
      setErrors({ ...errors, [field]: "required*" });
    } else {
      switch (field) {
        case "name":
          setErrors({ ...errors, [field]: validateName(value) });
          break;
        default:
          break;
      }
    }
  };

  const buttonClicked = () => {
    navigate("/profile");
  };

  useEffect(() => {
    const fetchCategoryList = () => {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("hotelId");

      fetch(`${API_URLS.BASE_URL}/category/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch categories");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data.data);
          setCategoryList(data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    };

    fetchCategoryList();
  }, []);

  const handleUpload = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    setSubmitted(true);
    if (editingCategory) {
      updateCategory(editingCategory.id);
    } else {
      addCategory();
    }
  };

  const addCategory = async () => {
    try {
      const id = localStorage.getItem("hotelId");
      const category = {
        name: name,
        id: id,
      };

      if (!name) {
        setErrors({ name: !name ? "required*" : validateName(name) });
        setIsSubmitting(false);
        return;
      }

      const token = localStorage.getItem("token");

      const response = await fetch(`${API_URLS.BASE_URL}/add-category`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(category),
      });

      if (response.ok) {
        setIsSubmitting(false);
        setOpen(true);
        setTimeout(() => {
          navigate("/profile");
        }, 1000);
      } else {
        setOpen(true);
        setErrors({ snackbar: "Failed to add category" });
        setIsSubmitting(false);
      }
    } catch (error) {
      setOpen(true);
      setErrors({ snackbar: "Failed to add category" });
      setIsSubmitting(false);
    }
  };

  const updateCategory = async (id) => {
    try {
      const category = { name };

      const token = localStorage.getItem("token");

      const response = await fetch(
        `${API_URLS.BASE_URL}/update-category/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(category),
        }
      );

      if (!response.ok) {
        setOpen(true);
        setErrors({ snackbar: "Failed to update category" });
      } else {
        setOpen(true);
        setCategoryList(
          categoryList.map((cat) => (cat.id === id ? { ...cat, name } : cat))
        );
        setEditingCategory(null);
        setName("");
        setTimeout(() => {
          navigate("/profile");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const deleteCategory = async (id) => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${API_URLS.BASE_URL}/delete-category/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        setOpen(true);
        setErrors({ snackbar: "Failed to delete category" });
      } else {
        setCategoryList(categoryList.filter((category) => category.id !== id));
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleEditClick = (category) => {
    setName(category.name);
    setEditingCategory(category);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Button onClick={buttonClicked} style={{ padding: 0 }}>
            <ArrowBackIcon
              style={{ color: "white", marginTop: "1px", cursor: "pointer" }}
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={10}>
          <div style={{ textAlign: "center", marginLeft: "-200px" }}>
            <h2 style={{ color: "white" }}>Manage Category</h2>
          </div>
          <Card className={classes.formContainer1}>
            <CardContent>
              <form style={{ marginTop: "40px" }}>
                <FormLabel label="Type" isRequired={true} />
                <br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => handleBlur("name", name)}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <Button
                style={{marginLeft:200}}
                  variant="button"
                  className={classes.button}
                  onClick={handleUpload}
                  disabled={isSubmitting}
                >
                  <b>{editingCategory ? "Update" : "Add"}</b>
                </Button>
              </form>
            </CardContent>
          </Card>

          <TableContainer
            component={Paper}
            className={classes.categoryList}
            sx={{ width: "600px", marginBottom: "20px" }}
          >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <b>Category Name</b>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryList.map((category) => (
                  <TableRow key={category.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {category.name}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <EditIcon
                        onClick={() => handleEditClick(category)}
                      ></EditIcon>
                      <DeleteOutlineIcon
                        onClick={() => deleteCategory(category.id)}
                      ></DeleteOutlineIcon>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            action={action}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {errors.snackbar ? (
              <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
                sx={{ width: "100%" }}
              >
                {errors.snackbar}
              </Alert>
            ) : (
              <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                Category Added Successfully.
              </Alert>
            )}
          </Snackbar>
        </Grid>
      </Grid>
    </div>
  );
};

export default ItemCategory;
