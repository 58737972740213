import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { brown } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import Alert from "@mui/material/Alert";
import { API_URLS } from "../utils/config";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import { Email, Phone } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#FCB98E",
  },
  cardContainer: {
    borderRadius: "45px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
    display: "flex",
    width: "80%",
    height: "80%",
    backgroundColor: "#FEF0E7",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "90%",
      marginBottom: "20px",
    },
  },
  skyContainer: {
    backgroundColor: "#463636",
    borderTopRightRadius: "45px",
    borderBottomRightRadius: "45px",
    width: "80%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      height: "47%",
      marginBottom: "0px",
      borderTopRightRadius: "45px",
      borderBottomRightRadius: "45px",
      borderBottomLeftRadius: "45px",
    },
  },
  login: {
    marginTop: "5%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1%",
    },
  },
  snackbarContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  },
  bottomRightImage: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "28%",
    height: "60%",
  },
  cardImage: {
    marginLeft: "20%",
    marginTop: "15%",
    height: "60%",
    width: "70%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "70%",
    marginLeft: "15%",
  },
}));

const theme = createTheme({
  palette: {
    primary: brown,
  },
});

const Login = () => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openNew, setOpenNew] = React.useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleUsernameBlur = () => {
    if (username === "") {
      setUsernameTouched(true);
    }
  };

  const handlePasswordBlur = () => {
    if (password === "") {
      setPasswordTouched(true);
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleLogin = async () => {
    if (!username || !password) {
      setError("Please fill in both username and password.");
      setUsernameTouched(true);
      setPasswordTouched(true);
      return;
    }
    try {
      setError("");
      const response = await fetch(`${API_URLS.BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const data = await response.json();
        setOpen(true);
        setError(
          data.message || "An error occurred during login. Please try again."
        );
        return;
      }

      const data = await response.json();
      const { token, userId, message, status, admin, hotelId } = data;

      if (status) {
        setOpenNew(true);
        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);
        if (hotelId) {
          localStorage.setItem("hotelId", hotelId);
        } else {
          localStorage.setItem("hotelId", 0);
        }
        localStorage.setItem("isAdmin", admin);
        setTimeout(() => {
          navigate("/today-offer");
        }, 800);
      } else {
        setOpen(true);
        setError(message);
      }
    } catch (error) {
      const message = "An error occurred during login. Please try again.";
      setOpen(true);
      setMessage(message);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.cardContainer}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h4"
                  className={classes.heading}
                  style={{
                    marginTop: "15%",
                    marginLeft: "40%",
                    color: "#463636",
                    [theme.breakpoints.down("sm")]: {
                      marginTop: "5%",
                    },
                  }}
                >
                  <b>Login </b>
                </Typography>
                <br />
                <TextField
                  label="Username*"
                  variant="standard"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onBlur={handleUsernameBlur}
                  style={{
                    width: "70%",
                    marginLeft: "15%",
                  }}
                  error={username === "" && usernameTouched}
                  helperText={
                    username === "" && usernameTouched ? "required*" : " "
                  }
                />
                <br />
                <div className="mb-4 flex">
                  <TextField
                    label="Password*"
                    variant="standard"
                    type={type}
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={handlePasswordBlur}
                    error={password === "" && passwordTouched}
                    helperText={
                      password === "" && passwordTouched ? "required*" : " "
                    }
                    style={{
                      width: "70%",
                      marginLeft: "15%",
                      marginTop: "2%",
                      color: "#463636",
                    }}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <Icon
                          className="absolute mr-10"
                          icon={icon}
                          onClick={handleToggle}
                          size={25}
                        />
                      ),
                    }}
                  />
                </div>
              </ThemeProvider>
              <Button
                style={{
                  width: "70%",
                  marginLeft: "15%",
                  backgroundColor: "#FF6B09",
                  height: "33px",
                  marginTop: "5%",
                  color: "white",
                  marginBottom: "10%",
                }}
                onClick={handleLogin}
              >
                <b>Login</b>
              </Button>
              <div className={classes.buttonContainer}>
                <Button
                  style={{
                    color: "black",
                    marginRight: "10px",
                  }}
                  onClick={handleClickOpen}
                >
                  <b>
                    <u>Contact Us</u>
                  </b>
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle sx={{ textAlign: 'center', color: 'orange' }}>Contact Us</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <div>
                        <p>
                          <Email />:{" "}
                          <Link href="mailto:info@marckitanalysts.com">
                            info@marckitanalysts.com
                          </Link>
                        </p>
                        <p><Phone />: +918320613343</p>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Button
                  style={{
                    color: "black",
                  }}
                  onClick={() => navigate("/inquiry-form")}
                >
                  <b>
                    <u>Inquiry</u>
                  </b>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.skyContainer}>
              <img
                src="./loginImage2.png"
                alt="image"
                className={classes.cardImage}
              />
            </Grid>
          </Grid>
        </div>
        <img
          src="./loginImage1.png"
          alt="Your Image"
          className={classes.bottomRightImage}
        />
        <Snackbar
          open={openNew}
          autoHideDuration={5000}
          onClose={handleClose}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Login Successfully.
          </Alert>
        </Snackbar>
        {error && (
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            action={action}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {error}
            </Alert>
          </Snackbar>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Login;
