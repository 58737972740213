import React, { useState, useEffect } from 'react';
import { Container, Grid, Button } from '@mui/material';
import OfferCardComponent from '../common/OfferCardComponent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { brown } from '@mui/material/colors';
import { API_URLS } from '../utils/config';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const theme = createTheme({
  palette: {
    primary: brown,
  },
});
const useStyles = makeStyles(() => ({
  loader: {
    margin: "auto",
    marginTop: "30%",
    width: "50px",
    aspectRatio: "1",
    borderRadius: "50%",
    border: "8px solid #0000",
    borderRightColor: "#ffa50097",
    position: "relative",
    animation: "$l24 1s infinite linear",
  },
  '@keyframes l24': {
    '100%': { transform: 'rotate(1turn)' }
  },
  '@global': {
    '.loader::before, .loader::after': {
      content: '""',
      position: 'absolute',
      inset: '-8px',
      borderRadius: '50%',
      border: 'inherit',
      animation: 'inherit',
      animationDuration: '2s'
    },
    '.loader::after': {
      animationDuration: '4s'
    }
  },
  activePage: {
    backgroundColor: '#463636',
    color: '#fff',
  },
  pageButton: {
    margin: '0 5px',
  }
}));

const AcceptedOfferComponent = () => {
  const [todayOffers, setTodayOffers] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const isAdmin = localStorage.getItem('isAdmin');
    if (isAdmin == true) {
      setAdmin(true);
    }
  }, []);

  useEffect(() => {
    const fetchTodayOffers = () => {
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('hotelId');
      const isAdmin = localStorage.getItem('isAdmin');
      if (isAdmin === "false") {
        fetch(`${API_URLS.BASE_URL}/today-offer/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            setTodayOffers(data.data);
            setLoading(false);
          })
          .catch((error) => {
            setError(error.message);
          });
      } else {
        fetch(`${API_URLS.BASE_URL}/today-offer`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            setTodayOffers(data.data);
            setLoading(false);
          })
          .catch((error) => {
            setError(error.message);
          });
      }
    };
    fetchTodayOffers();
  }, []);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
};
const buttonClicked = () => {
  navigate('/today-offer');
}


  if (loading) {
    return <div className={classes.loader}></div>
  }

 
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentOffers = todayOffers.slice(indexOfFirstItem, indexOfLastItem);
        const totalPages = Math.ceil(todayOffers.length / itemsPerPage);

        const pageNumbers = [];
        const maxPageButtons = 10;
        let startPage = Math.floor((currentPage - 1) / maxPageButtons) * maxPageButtons + 1;
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

  return (
    <div style={{ marginTop: "3%" }}>
        <ArrowBackIcon variant="button" onClick={buttonClicked} style={{ color: "#463636" ,cursor:"pointer",marginLeft:"5px"}} >  </ArrowBackIcon>
      <h2 style={{ marginLeft: '2%', color: "#463636" }}>Today accepted an offer by use</h2>
      <Container style={{ marginBottom: "2%", marginTop: "3%" }}>
        <Grid container spacing={2}>
          {currentOffers.map((offer) => (
            <Grid item xs={12} sm={6} md={3} lg={4} key={offer.id} style={{ marginBottom: "1%" }}>
              <OfferCardComponent offer={offer} />
            </Grid>
          ))}
        </Grid>
        <ThemeProvider theme={theme}>
                {totalPages > 1 && (
                    <div style={{
                        float: currentOffers.length <= 3 ? "right" : undefined,
                        bottom: currentOffers.length <= 3 ? '6px' : undefined,
                        right: '10px', position: currentOffers.length <= 3 ? 'absolute' : undefined,
                        display: currentOffers.length >= 3 ? 'flex' : undefined,
                        flexDirection: currentOffers.length >= 3 ? 'row' : undefined,
                        justifyContent: currentOffers.length >= 3 ? 'flex-end' : undefined,
                        display: 'flex',
                        flexWrap: 'wrap',
                        overflowY:"auto"
                    }}>
                        {startPage > 1 && (
                            <Button onClick={() => paginate(startPage - 1)}>&laquo; Prev</Button>
                        )}
                        {pageNumbers.map((pageNumber) => (
                            <Button key={pageNumber} onClick={() => paginate(pageNumber)} className={pageNumber === currentPage ? classes.activePage : classes.pageButton}>{pageNumber}</Button>
                        ))}
                        {endPage < totalPages && (
                            <Button onClick={() => paginate(endPage + 1)}>Next &raquo;</Button>
                        )}
                    </div>
                )}
                </ThemeProvider>
      </Container>
    </div>
  );
};

export default AcceptedOfferComponent;
