import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { Card, CardContent, TextField, Grid, Typography } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from '@mui/material/Alert';
import { API_URLS } from '../utils/config';
import FormLabel from '../common/FormLabel';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    background: '#463636',
    opacity: '60%',
    borderRadius: '2rem',
    padding: "20px",
    margin: "20px",
    flexGrow: 1,
  },
  formContainer1: {
    backgroundColor: '#FEF0E7',
    padding: "40px",
    margin: "30px",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: "30px",
    maxWidth: '600px',
    marginLeft:"30%"
  },
  textField: {
    marginTop: "8px !important",
    marginBottom: '18px !important',
    width: '100%',
  },
  input: {
    color: 'black',
    backgroundColor: 'white',
    border: '2px solid #FF6B09',
    borderRadius: '4px',
    height: '35px',
  },
  button: {
    display: 'flex',
    borderRadius: '0.3rem',
    alignItems: 'center',
    background: '#FF6B09 !important',
    justifyContent: 'center',
    color: 'white',
    marginLeft: "45% !important",
    marginTop: "10px"
  },
  errorText: {
    color: 'red',
  },
  titleContainer: {
    textAlign: 'center',
    width: '100%',
  },
  title: {
    color: 'white',
    marginBottom: '20px',
    fontSize: '5rem',
    fontWeight: 'bold',
  },
});

const UserForm = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    dateOfBirth: '',
    anniversaryDate: '', 
    mobileNumber: '',
    area: '',
    city: '',
    latitude: '',
    longitude: '',
  });
  const [errors, setErrors] = useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const validateField = (name, value) => {
    switch (name) {
      case 'userName':
        if (!/^[a-zA-Z\s]*$/.test(value)) {
          return 'Name should only contain letters and spaces';
        }
        break;
      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) {
          return 'Email is invalid';
        }
        break;
      case 'mobileNumber':
        if (!/^\d{10}$/.test(value)) {
          return 'Mobile Number should be 10 digits';
        }
        break;
      default:
        if (!value && name !== 'anniversaryDate' && name !== 'latitude' && name !== 'longitude') {
          return 'required*';
        }
        break;
    }
    return '';
  };

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleBlur = (name) => {
    if (!formData[name]) {
      setErrors({ ...errors, [name]: 'required*' });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    const token = localStorage.getItem('token');
    let formValid = true;
    const newErrors = {};

    Object.keys(formData).forEach(name => {
      const value = formData[name];
      const error = validateField(name, value);
      if (error) {
        newErrors[name] = error;
        formValid = false;
      }
    });

    if (!formValid) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await fetch(`${API_URLS.BASE_URL}/add-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setOpenSnackbar(true);
        setFormData({
          userName: '',
          email: '',
          dateOfBirth: '',
          anniversaryDate: '',
          mobileNumber: '',
          area: '',
          city: '',
          latitude: '',
          longitude: ''  
        });
        setTimeout(() => {
          navigate('/users');
        }, 1000); 
      } else {
        const errorData = await response.json();
        setOpen(true);
        setErrors(errorData.message);  
      }
    } catch (error) {
      console.error('Error:', error);
      setOpen(true);
      setErrors('An error occurred, please try again later.');
    }
  };
  
  const buttonClicked = () => {
    navigate('/users');
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <ArrowBackIcon variant="button" onClick={buttonClicked} style={{ color: "white", marginTop: "1px", cursor: "pointer" }} />
        </Grid>
        <Grid item xs={12} sm={8} className={classes.titleContainer}>
          <Typography className={classes.title} style={{fontSize: '2rem'}}>Add User</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Card className={classes.formContainer1} style={{borderRadius: '2rem'}}>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <FormLabel label="Name" isRequired={true} /><br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  name="userName"
                  value={formData.userName}
                  onChange={(event) => handleChange('userName', event.target.value)}
                  onBlur={() => handleBlur('userName')}
                  error={!!errors.userName}
                  helperText={<span className={classes.errorText}>{errors.userName}</span>}
                />
                <FormLabel label="Email" isRequired={true} /><br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  name="email"
                  value={formData.email}
                  onChange={(event) => handleChange('email', event.target.value)}
                  onBlur={() => handleBlur('email')}
                  error={!!errors.email}
                  helperText={<span className={classes.errorText}>{errors.email}</span>}
                />
                <FormLabel label="Mobile Number" isRequired={true} /><br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={(event) => handleChange('mobileNumber', event.target.value)}
                  onBlur={() => handleBlur('mobileNumber')}
                  error={!!errors.mobileNumber}
                  helperText={<span className={classes.errorText}>{errors.mobileNumber}</span>}
                />
                <FormLabel label="Date Of Birth" isRequired={true} /><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']} >
                    <DatePicker
                      className={classes.textField}
                      value={formData.dateOfBirth}
                      fullWidth
                      onChange={(value) => handleChange('dateOfBirth', value)}
                      disableFuture
                      slotProps={{ textField: { size: 'small' } }}
                      sx={{ width: "100%", overflowY: "hidden", border: '2px solid #FF6B09', borderRadius: '5px' }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <FormLabel label="Anniversary Date" isRequired={false} /><br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']} >
                    <DatePicker
                      className={classes.textField}
                      value={formData.anniversaryDate}
                      fullWidth
                      onChange={(value) => handleChange('anniversaryDate', value)}
                      disableFuture
                      slotProps={{ textField: { size: 'small' } }}
                      sx={{ width: "100%", overflowY: "hidden", border: '2px solid #FF6B09', borderRadius: '5px' }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <FormLabel label="Area" isRequired={true} /><br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  name="area"
                  value={formData.area}
                  onChange={(event) => handleChange('area', event.target.value)}
                  onBlur={() => handleBlur('area')}
                  error={!!errors.area}
                  helperText={<span className={classes.errorText}>{errors.area}</span>}
                />
                <FormLabel label="City" isRequired={true} /><br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  name="city"
                  value={formData.city}
                  onChange={(event) => handleChange('city', event.target.value)}
                  onBlur={() => handleBlur('city')}
                  error={!!errors.city}
                  helperText={<span className={classes.errorText}>{errors.city}</span>}
                />
                <FormLabel label="Latitude" isRequired={false} /><br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  name="latitude"
                  value={formData.latitude}
                  onChange={(event) => handleChange('latitude', event.target.value)}
                  onBlur={() => handleBlur('latitude')}
                  error={!!errors.latitude}
                  helperText={<span className={classes.errorText}>{errors.latitude}</span>}
                />
                <FormLabel label="Longitude" isRequired={false} /><br />
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  InputProps={{ className: classes.input }}
                  name="longitude"
                  value={formData.longitude}
                  onChange={(event) => handleChange('longitude', event.target.value)}
                  onBlur={() => handleBlur('longitude')}
                  error={!!errors.longitude}
                  helperText={<span className={classes.errorText}>{errors.longitude}</span>}
                />
                <Button type="submit" className={classes.button}>
                  Add
                </Button>
              </form>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message="User Added"
                action={action}
              />
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
              >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  {errors}
                </Alert>
              </Snackbar>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default UserForm;
