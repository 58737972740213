import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import { API_URLS } from "../utils/config";

const drawerWidth = "280px";
const drawerHeight = "100vh";

const windowHeight = window.innerHeight;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    height: drawerHeight,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#FEF0E7 !important",
    borderRight: "0 !important",
  },
  container: {
    backgroundColor: "#463636",
    height: windowHeight < 600 ? "93%" : "94%",
    width: drawerWidth,
    alignItems: "center",
    position: "fixed",
    borderRadius: "0 30px 30px 0",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sideBarItems: {
    borderRadius: "30px 0 0 30px",
    height: windowHeight < 600 ? "24%" : "100%",
    cursor: "pointer",
    color: "white",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  activeItem: {
    background: "#FEF0E7",
    color: "black",
  },
}));

const Sidebar = () => {
  const [active, setActive] = useState("offer");
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [hotelName, setHotelName] = useState("");
  const [hotelLogo, setHotelLogo] = useState("");
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [admin, setAdmin] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin");
    if (isAdmin === "true") {
      setAdmin(true);
    }
  }, []);

  useEffect(() => {
    const fetchUserDetail = async () => {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("hotelId");
      try {
        const response = await fetch(
          `${API_URLS.BASE_URL}/hotel-name-logo/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const { data } = await response.json();
        setHotelName(data.name);
        if (data.logo != null) {
          setHotelLogo(data.logo);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUserDetail();
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("offer") || path.includes("today-offer")) {
      setActive("offer");
    } else if (path.includes("history")) {
      setActive("history");
    } else if (path.includes("user")) {
      setActive("users");
    } else if (
      path.includes("profile") ||
      path.includes("hotel-details") ||
      path.includes("add-category") ||
      path.includes("add-item") ||
      path.includes("type") ||
      path.includes("subtype") ||
      path.includes("add-hotel")
    ) {
      setActive("profile");
    } else if (path.includes("setting")) {
      setActive("setting");
    } else if (path.includes("InquiryHistory")) {
      setActive("InquiryHistory");
    } else {
      setActive("");
    }
  }, [location]);

  const redirectToRoute = (route) => {
    setActive(route);
    navigate(`/${route}`);
  };

  const redirectToLogOut = () => {
    setActive("logOut");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("hotelId");
    localStorage.removeItem("isAdmin");
    navigate("/login");
  };

  const drawerContent = (
    <div>
      <Typography
        className={classes.container}
        sx={{
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Typography
          variant="div"
          sx={{ display: "grid", justifyContent: "center" }}
        >
          <Typography
            className={classes.imageContainer}
            sx={{ paddingTop: "1rem" }}
          >
            <img src="/Group_165.svg" alt="Your Image" />
          </Typography>

          <Typography
            className={classes.imageContainer}
            sx={{ paddingTop: "1rem" }}
          >
            {hotelLogo ? (
              <img
                src={hotelLogo}
                alt="Hotel Logo"
                style={{ borderRadius: "70%", width: "100px", height: "100px" }}
              />
            ) : (
              <img
                src="/MaskGroup.png"
                alt="Default Hotel Logo"
                style={{ borderRadius: "70%", width: "100px", height: "100px" }}
              />
            )}
          </Typography>

          {admin && (
            <Typography
              variant="h5"
              className={classes.imageContainer}
              sx={{
                padding: "0.3rem 0 0.5rem 0",
                color: "white",
                marginTop: "2px",
              }}
            >
              Admin
            </Typography>
          )}

          <Typography
            variant="h5"
            className={classes.imageContainer}
            sx={{ padding: "0.3rem 0 0.5rem 0", color: "white" }}
          >
            {hotelName}
          </Typography>
        </Typography>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            height: windowHeight < 600 ? "22%" : "10%",
          }}
        >
          <ListItem
            onClick={() => redirectToRoute("offer")}
            className={`${classes.sideBarItems} ${
              active === "offer" ? classes.activeItem : ""
            }`}
          >
            <ListItemIcon sx={{ paddingLeft: "5%" }}>
              {active === "offer" ? (
                <img src="/Offers-Icon.svg" alt="Offers_Icon" />
              ) : (
                <img src="/offer.svg" alt="Offers_Icon" />
              )}
            </ListItemIcon>
            <ListItemText primary="All Offer" />
          </ListItem>
          <ListItem
            onClick={() => redirectToRoute("history")}
            className={`${classes.sideBarItems} ${
              active === "history" ? classes.activeItem : ""
            }`}
          >
            <ListItemIcon sx={{ paddingLeft: "5%" }}>
              {active === "history" ? (
                <img src="/history_icon_cl.svg" alt="History_Icon" />
              ) : (
                <img src="/History_icon.svg" alt="History_Icon" />
              )}
            </ListItemIcon>
            <ListItemText primary="Offer Response Card" />
          </ListItem>

          <ListItem
            onClick={() => redirectToRoute("users")}
            className={`${classes.sideBarItems} ${
              active === "users" ? classes.activeItem : ""
            }`}
          >
            <ListItemIcon sx={{ paddingLeft: "5%" }}>
              {active === "users" ? (
                <img src="/users-icon_cl.svg" alt="Users_Icon" />
              ) : (
                <img src="/Users_icon.svg" alt="Users_Icon" />
              )}
            </ListItemIcon>
            <ListItemText primary={admin ? "All Users" : "Users"} />
          </ListItem>

          <ListItem
            onClick={() => redirectToRoute(admin ? "hotel-details" : "profile")}
            className={`${classes.sideBarItems} ${
              active === "profile" ? classes.activeItem : ""
            }`}
          >
            <ListItemIcon sx={{ paddingLeft: "5%" }}>
              {active === "profile" ? (
                <img src="/profile_icon_cl.svg" alt="Profile_Icon" />
              ) : (
                <img src="/Profile_icon.svg" alt="Profile_Icon" />
              )}
            </ListItemIcon>
            <ListItemText primary={admin ? "Hotel List" : "Profile"} />
          </ListItem>

          {admin && (
            <ListItem
              onClick={() => redirectToRoute("InquiryHistory")}
              className={`${classes.sideBarItems} ${
                active === "InquiryHistory" ? classes.activeItem : ""
              }`}
            >
              <ListItemIcon sx={{ paddingLeft: "5%" }}>
                {active === "InquiryHistory" ? (
                  <img src="/time.svg" alt="Time" />
                ) : (
                  <img src="/time.svg" alt="Time" />
                )}
              </ListItemIcon>
              <ListItemText primary="Inquiry History" />
            </ListItem>
          )}
          {/* <ListItem onClick={() => redirectToRoute("setting")} className={`${classes.sideBarItems} ${active === "setting" ? classes.activeItem : ""}`}>
                        <ListItemIcon sx={{ paddingLeft: "5%" }}>
                            {active === "setting" ? (
                                <img src="/setting_icon_cl.svg" alt="Setting_Icon" />
                            ) : (
                                <img src="/setting_icon.svg" alt="Setting_Icon" />
                            )}
                        </ListItemIcon>
                        <ListItemText primary="Setting" />
                    </ListItem> */}

          <ListItem
            onClick={redirectToLogOut}
            sx={{ marginTop: windowHeight < 600 ? "30%" : "50%" }}
            className={`${classes.sideBarItems} ${
              active === "logOut" ? classes.activeItem : ""
            }`}
          >
            <ListItemIcon sx={{ paddingLeft: "5%" }}>
              {active === "logOut" ? (
                <img src="/logout_cl.svg" alt="LogOut_Icon" />
              ) : (
                <img src="/Logout_icon.svg" alt="LogOut_Icon" />
              )}
            </ListItemIcon>
            <ListItemText primary="LogOut" />
          </ListItem>
        </List>
      </Typography>
    </div>
  );

  return (
    <div className={classes.root}>
      {isTabletOrMobile ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      ) : (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawerContent}
        </Drawer>
      )}

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {drawerContent}
      </Drawer>
    </div>
  );
};

export default Sidebar;
