import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
// import "bootstrap/dist/css/bootstrap.min.css";

import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { Modal } from "@mui/material";
import HotelForm from "./hotelForm";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_URLS } from "../utils/config";
import { ShimmerTable } from "react-shimmer-effects";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const windowHeight = window.innerHeight;

const useStyles = makeStyles(() => ({
  bannerImage: {
    marginTop: "20px",
    width: "97%",
    height: "55vh",
    borderRadius: "20px",
    margin: windowHeight < 600 ? "28px 28px 10px 28px" : "0 0 10px 0",
    objectFit: "cover",
  },
  cardItem: {
    marginTop: "10px ",
    borderRadius: "20px",
    boxShadow: "none !important",
    backgroundColor: "transparent !important",
    width: "200px",
    height: "150px",
  },
  cardMedia: {
    borderRadius: "20px",
  },
  addButton: {
    backgroundColor: "#FF6B09",
    color: "white",
    height: "57px",
    width: "139px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  },
  image: {
    width: "7%",
    height: "7%",
    // alignItems: "center",
    marginTop: 15,
    alignSelf: "center",
  },
  loader: {
    margin: "auto",
    marginTop: "30%",
    width: "50px",
    aspectRatio: "1",
    borderRadius: "50%",
    border: "8px solid #0000",
    borderRightColor: "#ffa50097",
    position: "relative",
    animation: "$l24 1s infinite linear",
  },
  "@keyframes l24": {
    "100%": { transform: "rotate(1turn)" },
  },
  "@global": {
    ".loader::before, .loader::after": {
      content: '""',
      position: "absolute",
      inset: "-8px",
      borderRadius: "50%",
      border: "inherit",
      animation: "inherit",
      animationDuration: "2s",
    },
    ".loader::after": {
      animationDuration: "4s",
    },
  },
}));

const Profile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [restaurantData, setRestaurantData] = useState(null);
  const [restaurantImages, setRestaurantImages] = useState([]);
  const [restaurantMenu, setRestaurantMenu] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [menuLoading, setMenuLoading] = useState(true);

  const [data, setData] = useState([]);
  const [selectedIndex, setIndex] = useState(null);
  const [collapsedCategories, setCollapsedCategories] = useState({});

  const toggleCollapse = (index) => {
    setIndex(selectedIndex === index ? null : index);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchRestaurantImages();
    fetchRestaurantData();
  };
  // useEffect(() => {

  //     const delay = setTimeout(() => {

  //     }, 1500);
  //     return () => clearTimeout(delay);
  //   }, []);
  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  useEffect(() => {
    fetchRestaurantData();
    fetchRestaurantImages();
    fetchCategoryList();
    fetchRestaurantMenu();
  }, []);

  const fetchCategoryList = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("hotelId");

    fetch(`${API_URLS.BASE_URL}/category/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.data);
        setCategoryList(data.data);
      })
      .catch((error) => {});
  };

  const handleUpdateItemClick = (itemId) => {
    navigate(`/update-item/itemId=${itemId}`);
  };

  const handleDeleteItemClick = (id) => {
    setDeleteItemId(id);
    setDeleteOpen(true);
  };

  const handleDeleteConfirmation = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const id = deleteItemId;
      const response = await fetch(`${API_URLS.BASE_URL}/item/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        fetchRestaurantMenu();
        setDeleteOpen(false);
      } else {
        throw new Error("Failed to delete item");
      }
    } catch (error) {}
  };

  const handleUpdateHotel = () => {
    navigate("/update-hotel");
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const fetchRestaurantData = async () => {
    try {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("hotelId");
      const response = await fetch(`${API_URLS.BASE_URL}/hotel-details/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch restaurant data");
      }
      const data = await response.json();
      if (data?.data) {
        setRestaurantData(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchRestaurantImages = async () => {
    try {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("hotelId");
      const response = await fetch(`${API_URLS.BASE_URL}/hotel-images/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch restaurant data");
      }
      const data = await response.json();
      setRestaurantImages(data.data);
    } catch (error) {}
  };

  const fetchRestaurantMenu = async () => {
    try {
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("hotelId");
      const response = await fetch(`${API_URLS.BASE_URL}/item-by-hotel/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch restaurant data");
      }

      const data = await response.json();
      // Fetch images for each menu item
      const updatedMenu = await Promise.all(
        data?.data?.map(async (menuItem) => {
          const imageResponse = await fetch(
            `${API_URLS.BASE_URL}/item/image/${menuItem.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!imageResponse.ok) {
            throw new Error(`HTTP error! Status: ${imageResponse.status}`);
          }

          const imageBlob = await imageResponse.blob();
          const imageObjectURL = URL.createObjectURL(imageBlob);
          return { ...menuItem, image: imageObjectURL };
        })
      );

      if (updatedMenu) {
        for (let i = 0; i < updatedMenu?.length; i++) {
          const element = updatedMenu[i];
          setRestaurantMenu(...restaurantMenu, { category: element?.category });
        }
        setRestaurantMenu(updatedMenu);
      }
    } catch (error) {
      console.error("Error fetching restaurant menu:", error);
    }
  };

  useEffect(() => {
    if (restaurantMenu?.length && categoryList?.length && !data?.length) {
      for (let i = 0; i < categoryList?.length; i++) {
        const element = categoryList[i];
        data.push({ category: element?.name });
      }
      data?.map((a) => {
        const filterData = restaurantMenu?.filter((b) => {
          return b?.category === a?.category;
        });

        a["item"] = filterData;
      });
      setMenuLoading(false);
    }
  }, [restaurantMenu, categoryList]);

  const handleAddUserClick = () => {
    navigate("/add-item");
  };

  const handleAddCategory = () => {
    navigate("/add-category");
  };

  if (loading) {
    return <div className={classes.loader}></div>;
  }
  console.log("loader....", menuLoading);
  console.log("data....", data);

  return (
    <Typography
      sx={{ marginBottom: "20px", width: windowHeight < 600 ? "98%" : "100%" }}
    >
      {restaurantImages && (
        <Box>
          <Typography sx={{ marginBottom: "20px" }}>
            {restaurantImages.length > 1 ? (
              <Slider {...settings}>
                {restaurantImages.map((imageData, index) => (
                  <div key={index}>
                    <img
                      src={imageData.image}
                      alt={`Image ${index}`}
                      className={classes.bannerImage}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              restaurantImages.length > 0 && (
                <img
                  src={restaurantImages[0].image}
                  alt="Single Image"
                  className={classes.bannerImage}
                />
              )
            )}
          </Typography>
          <Grid
            container
            // spacing={2}
            sx={{
              width: windowHeight < 600 ? "98%" : "calc(100% + 16px)",
              margin: windowHeight < 600 ? "26px" : "0 0 0 -16px",
            }}
          >
            <Grid item xs={10}>
              <Typography variant="h4">Description</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                <span style={{ color: "#FF6B09" }}>
                  <b>Open</b>
                </span>{" "}
                <b>at {formatTime(restaurantData.openingTime)}</b>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>{restaurantData.description}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                <span style={{ color: "#FF6B09" }}>
                  <b>Close</b>
                </span>{" "}
                <b>at {formatTime(restaurantData.closingTime)}</b>
              </Typography>
            </Grid>
            <Grid item xs={0.3}>
              <img src="/Location-icon.svg" alt="Location Icon" />
            </Grid>
            <Grid
              item
              xs={11.7}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">{restaurantData.address}</Typography>
              <div>
                <EditIcon
                  onClick={handleOpen}
                  style={{ marginRight: "50px" }}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  style={{
                    width: "auto%",
                    marginLeft: "30%",
                    marginTop: "5%",
                    height: "auto",
                  }}
                >
                  <Box className={classes.modalContainer}>
                    <HotelForm close={handleClose} />
                  </Box>
                </Modal>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h4">Menu</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={10}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <Grid item xs={12} sm={4} md={2}>
                  <button
                    className={classes.addButton}
                    onClick={handleAddCategory}
                  >
                    Manage Category
                  </button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  sx={{ marginLeft: "-30px !important" }}
                >
                  <button
                    className={classes.addButton}
                    onClick={handleAddUserClick}
                  >
                    + Add Menu
                  </button>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            {menuLoading && (
              <Grid item xs={12}>
                <ShimmerTable row={3} col={3} />
                <ShimmerTable row={3} col={3} />
              </Grid>
            )}
            {data?.length ? (
              data?.map((a, index) => (
                <Grid item xs={12} key={index}>
                  <div style={{ marginTop: "16px" }}>
                    <button
                      type="button"
                      style={{
                        backgroundColor: "#FF6B09",
                        color: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "96%",
                        borderRadius: 10,
                        border: "none",
                        padding: "10px",
                        textAlign: "left",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleCollapse(index)}
                    >
                      {a.category}
                      <ExpandMoreIcon />
                    </button>
                    <div
                      style={{
                        display: selectedIndex !== index ? "none" : "block",
                      }}
                    >
                      {a?.item?.length ? (
                        a?.item
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map((menuItem, index) => (
                            <Grid
                              container
                              key={index}
                              style={{
                                display: "flex",
                                marginTop: "10px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "96%",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: 10,
                                padding: 5,
                              }}
                            >
                              <Grid
                                style={{
                                  borderRadius: "10px",
                                  boxShadow: "none",
                                  width: 230,
                                  height: 150,
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  height="100%"
                                  width="95%"
                                  image={menuItem.image}
                                  alt={menuItem.name}
                                  style={{
                                    borderRadius: "10px",
                                    height: "150px",
                                    objectFit: "cover",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xl={9.5}
                                lg={9}
                                md={8.5}
                                sm={8.3}
                                xs={12}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  borderRadius: "10px",
                                  boxShadow: "none",
                                  width: "80%",
                                  padding: 10,
                                  justifyContent: "space-between",
                                }}
                              >
                                <CardContent
                                  style={{
                                    width: "85%",
                                    height: "75%",
                                    alignItems: "flex-start",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography variant="h5" component="h2">
                                    {menuItem.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    ₹ {menuItem.price}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {menuItem.category}
                                  </Typography>
                                </CardContent>
                                <Grid
                                  item
                                  style={{
                                    width: "10%",
                                    height: 40,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: 10,
                                  }}
                                >
                                  <EditIcon
                                    onClick={() =>
                                      handleUpdateItemClick(menuItem.id)
                                    }
                                    style={{
                                      marginRight: "10%",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <DeleteOutlineIcon
                                    onClick={() =>
                                      handleDeleteItemClick(menuItem.id)
                                    }
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 1,
                            padding: "20px",
                          }}
                        >
                          <img
                            src={require("../assert/nofood.png")}
                            alt="No Menu"
                            style={{ width: "100px", height: "auto" }}
                          />
                          <div style={{ color: "#A5A5A5", marginTop: "10px" }}>
                            No menu available!
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
              ))
            ) : (
              <>Data not found.</>
            )}

            <Modal
              open={deleteOpen}
              onClose={() => setDeleteOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                style={{
                  width: "300px",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{ marginBottom: "16px" }}
                >
                  Are you sure you want to delete this item?
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={() => setDeleteOpen(false)}
                    color="primary"
                    variant="contained"
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDeleteConfirmation}
                    color="error"
                    variant="contained"
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Grid>
        </Box>
      )}
    </Typography>
  );
};

export default Profile;
