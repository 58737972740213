import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CardMedia from "@mui/material/CardMedia";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_URLS } from "../utils/config";
import { styled } from "@mui/system";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
  IconButton,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EditHotelDialog from "./EditHotelDialog";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "20px",
  },
  addButton: {
    backgroundColor: "#FF6B09",
    color: "white",
    height: "57px",
    width: "139px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    margin: "5px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    maxWidth: 345,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      maxWidth: "100%",
    },
  },
  media: {
    height: 140,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    padding: 10,
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  searchInput: {
    width: "100%",
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
      marginBottom: 0,
    },
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    display: "flex",
    padding: 10,
  },
  statusText: {
    padding: 6,
  },
  messageContainer: {
    marginBottom: "20px",
  },
}));

const CustomButton = styled(Button)(({ status }) => ({
  backgroundColor: status ? "green" : "red",
  color: "white",
  "&:hover": {
    backgroundColor: status ? "green" : "red",
    color: "white",
  },
}));

const HotelDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [restaurantData, setRestaurantData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentHotel, setCurrentHotel] = useState(null);
  const [lowMessageDialogOpen, setLowMessageDialogOpen] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const fetchHotelList = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`${API_URLS.BASE_URL}/hotel-list`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const hotels = data.data;

        // Fetch remaining messages for each hotel
        for (const hotel of hotels) {
          const remainingMessages = await fetchRemainingMessages(hotel.id);
          hotel.remainingMessages = remainingMessages;
        }

        setRestaurantData(hotels);
        setLoading(false);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchHotelList();
  }, []);

  const fetchRemainingMessages = async (hotelId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_URLS.BASE_URL}/total-number-of-messages/${hotelId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch remaining messages");
      }

      const data = await response.json();
      return 200 - data.data;
    } catch (error) {
      console.error("Error fetching remaining messages:", error);
      return null;
    }
  };

  const handleAddHotel = () => {
    navigate("/add-hotel");
  };

  const handleAddCategory = () => {
    navigate("/type");
  };

  const handleAddSubCategory = () => {
    navigate("/subtype");
  };

  const handleStatusChange = (id) => {
    const token = localStorage.getItem("token");

    fetch(`${API_URLS.BASE_URL}/hotel-status/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          setRestaurantData((prevData) =>
            prevData.map((hotel) =>
              hotel.id === id
                ? { ...hotel, hotelStatus: !hotel.hotelStatus }
                : hotel
            )
          );
        } else {
          console.error("Failed to update status:", data.message);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const handleEditClick = (hotel) => {
    setCurrentHotel(hotel);
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setCurrentHotel(null);
  };

  const handleSave = (updatedHotel) => {
    const token = localStorage.getItem("token");

    fetch(`${API_URLS.BASE_URL}/update-hotel-name/${updatedHotel.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedHotel),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          setRestaurantData((prevData) =>
            prevData.map((hotel) =>
              hotel.id === updatedHotel.id ? { ...hotel, ...updatedHotel } : hotel
            )
          );
          handleDialogClose();
        } else {
          console.error("Failed to update hotel:", data.message);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleLowMessageDialogClose = () => {
    setLowMessageDialogOpen(false);
  };

  const filteredHotels = restaurantData.filter((hotel) =>
    hotel.name.toLowerCase().includes(searchTerm)
  );

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <TextField
          className={classes.searchInput}
          label=" Search by Hotel Name"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div style={{ flexDirection: "row", display: "flex" }}>
          <button className={classes.addButton} onClick={handleAddHotel}>
            {" "}
            + Add Hotel
          </button>
          <button className={classes.addButton} onClick={handleAddCategory}>
            + Add Category
          </button>
          <button className={classes.addButton} onClick={handleAddSubCategory}>
            + Add SubCategory
          </button>
        </div>
      </div>
      {loading ? (
        <Grid container spacing={3}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className={classes.card}>
                <Skeleton height={140} />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    <Skeleton />
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    <Skeleton count={3} />
                  </Typography>
                  <Button status={false}>
                    <Skeleton width={100} />
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : filteredHotels.length > 0 ? (
        <Grid container spacing={3}>
          {filteredHotels.map((hotel) => (
            <Grid item xs={12} sm={6} md={4} key={hotel.id}>
              <Card className={classes.card}>
                {hotel.image && hotel.image.length > 1 ? (
                  <Slider {...settings}>
                    {hotel.image.map((imageData, index) => (
                      <CardMedia
                        className={classes.media}
                        key={index}
                        image={imageData.image}
                      />
                    ))}
                  </Slider>
                ) : (
                  hotel.image &&
                  hotel.image.length === 1 && (
                    <CardMedia
                      className={classes.media}
                      image={hotel.image[0].image}
                    />
                  )
                )}
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {hotel.name}
                    <IconButton sx={{ float: "right" }} onClick={() => handleEditClick(hotel)}>
                      <EditIcon />
                    </IconButton>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {hotel.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {hotel.address}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Opening Time: {formatTime(hotel.openingTime)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Closing Time: {formatTime(hotel.closingTime)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Hotel Owner/Manager email : {hotel.hotelOwnerUserName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="h3">
                    Items:
                  </Typography>
                  <ul sx={{ marginTop: "0px !important" }}>
                    {hotel.itemList.slice(0, 4).map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))}
                  </ul>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Remaining Messages: {hotel.remainingMessages || 0}
                  </Typography>
                </CardContent>
                <div className={classes.buttonContainer}>
                  <Typography
                    className={classes.statusText}
                    style={{ color: hotel.hotelStatus ? "red" : "green" }}
                  >
                    Status: {hotel.hotelStatus ? "Deactivate" : "Activate"}
                  </Typography>
                  <CustomButton
                    variant="contained"
                    status={hotel.hotelStatus}
                    onClick={() => handleStatusChange(hotel.id)}
                  >
                    {hotel.hotelStatus ? "Activate" : "Deactivate"}
                  </CustomButton>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography align="center">
          <img src={require("../assert/noData.png")} alt="No Data Available" />
        </Typography>
      )}
      {currentHotel && (
        <EditHotelDialog
          open={editDialogOpen}
          handleClose={handleDialogClose}
          hotelData={currentHotel}
          handleSave={handleSave}
        />
      )}
      <Dialog open={lowMessageDialogOpen} onClose={handleLowMessageDialogClose}>
        <DialogTitle>Low Message Limit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The remaining message limit for this hotel is low. Please take necessary actions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLowMessageDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HotelDetails;
