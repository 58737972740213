import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TextField,
  InputAdornment,
  TableRow,
  TableCell,
  Checkbox,
} from "@mui/material";
import { API_URLS } from "../utils/config";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  containerMain: {
    background: "#463636",
    padding: "20px",
  },
  listcontainer: {
    display: "flex",
    justifyItems: "center",
    margin: "20px !important",
    background: "#FEF0E7",
    borderRadius: "2rem",
    width: "94%",
    padding: "70px 10px 20px 20px !important",
  },
  table: {
    width: "100%",
  },
  buttondiv: {
    display: "flex",
    alignItems:'center',
    justifyContent: "space-evenly",
    marginTop: "20px",
  },
  button: {
    height: "60px",
    width: "300px",
    margin: 10,
    borderRadius: "2rem",
    background: "#FCB98E !important",
    color: "white",
  },
  activeItem: {
    height: "60px",
    width: "300px",
    borderRadius: "2rem",
    background: "#FCB98E !important",
    color: "black !important",
  },
  tableCell: {
    padding: "0px !important",
  },
  loader: {
    margin: "auto",
    marginTop: "30%",
    width: "50px",
    aspectRatio: "1",
    borderRadius: "50%",
    border: "8px solid #0000",
    borderRightColor: "#ffa50097",
    position: "relative",
    animation: "$l24 1s infinite linear",
  },
  "@keyframes l24": {
    "100%": { transform: "rotate(1turn)" },
  },
  "@global": {
    ".loader::before, .loader::after": {
      content: '""',
      position: "absolute",
      inset: "-8px",
      borderRadius: "50%",
      border: "inherit",
      animation: "inherit",
      animationDuration: "2s",
    },
    ".loader::after": {
      animationDuration: "4s",
    },
  },
}));

const PersonList = ({
  onSelectUsers,
  selectedUsers: propSelectedUsers,
  onCloseModal,
  selectedstartdate: propStartDate,
  selectedofferType: propOfferTypeId,
}) => {
  const [personList, setPersonList] = useState([]);
  const [offerTypeList, setOfferTypeList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(propStartDate);
  const [selectedOfferType, setSelectedOfferType] = useState(propOfferTypeId);
  const [error, setError] = useState(null);
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleButtonClick = (buttonName) => {
    if (buttonName === "Back") {
      setCurrentPage(currentPage - 1);
    } else if (buttonName === "Next") {
      if (currentPage === totalPages) {
        handleSubmit();
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  useEffect(() => {
    setSelectedUsers(propSelectedUsers);
  }, [propSelectedUsers]);

  useEffect(() => {
    setSelectedStartDate(propStartDate);
  }, [propStartDate]);

  useEffect(() => {
    setSelectedOfferType(propOfferTypeId);
  }, [propOfferTypeId]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allUserIds = personList.map((person) => person.id);
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
    }
  };

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const token = localStorage.getItem("token");
        const hotelId = localStorage.getItem("hotelId");
        const response = await fetch(
          `${API_URLS.BASE_URL}/nearby?hotelId=${hotelId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        let filteredList = data.data;
        console.log(selectedOfferType);
        if (selectedOfferType == 1) {
          filteredList = filteredList.filter((person) => {
            const birthdate = new Date(person.dateOfBirth);
            const start = new Date(selectedStartDate);
            return (
              birthdate.getDate() === start.getDate() &&
              birthdate.getMonth() === start.getMonth() &&
              person.useBirthOffer === false
            );
          });
          setPersonList(filteredList);
          setFilteredData(filteredList);
        }
        if (selectedOfferType == 5) {
          filteredList = filteredList.filter((person) => {
            if (person.anniversaryDate) {
              const birthdate = new Date(person.anniversaryDate);
              const start = new Date(selectedStartDate);
              return (
                birthdate.getDate() === start.getDate() &&
                birthdate.getMonth() === start.getMonth() &&
                person.useAnniversaryOffer === false
              );
            }
          });
          setPersonList(filteredList);
          setFilteredData(filteredList);
        }
        setPersonList(data.data);
        setFilteredData(data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUserList();
  }, [currentPage]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = personList.filter(
      (user) =>
        user.userName.toLowerCase().includes(searchTerm) ||
        user.email?.toLowerCase().includes(searchTerm) ||
        user.mobileNumber?.includes(searchTerm) ||
        user.city?.toLowerCase().includes(searchTerm) ||
        user.area?.toLowerCase().includes(searchTerm)
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    const fetchOfferTypes = () => {
      const token = localStorage.getItem("token");
      fetch(`${API_URLS.BASE_URL}/V1/List-of-offer-type`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setOfferTypeList(data.data);
        })
        .catch((error) => {
          setError(error.message);
        });
    };
    fetchOfferTypes();
  }, []);

  const handleUserSelect = (userId) => {
    const index = selectedUsers.indexOf(userId);
    if (index === -1) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSelectUsers(selectedUsers);
    onCloseModal();
  };

  const calculateAge = (dateOfBirth) => {
    const dob = new Date(dateOfBirth);
    const ageDiffMs = Date.now() - dob.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const getNameByOfferSubType = (offerList) => {
    const offerSubTypeNames = [];
    if (offerList && offerList.length > 0) {
      offerList.forEach((offer) => {
        const { offerSubTypeId } = offer;
        offerSubTypeNames.push(offerSubTypeId.name);
      });
      return offerSubTypeNames.join(",");
    }
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const slicedPersonList = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (loading) {
    return <div className={classes.loader}></div>;
  }
  return (
    <Typography className={classes.containerMain}>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginLeft: 15,
          width: "95%",
        }}
      >
        <TextField
          fullWidth
          sx={{ backgroundColor: "white", borderRadius: "10px" }}
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Typography className={classes.listcontainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "180px!important" }}>
                <Checkbox
                  checked={selectedUsers.length === personList.length}
                  onChange={handleSelectAll}
                />
                Select All ({selectedUsers.length})
              </TableCell>
              <TableCell className={classes.tableCell}>Name</TableCell>
              <TableCell className={classes.tableCell}>Birthday</TableCell>
              <TableCell className={classes.tableCell}>Phone Number</TableCell>
              <TableCell className={classes.tableCell}>Age</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedPersonList.map((PersonList, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={selectedUsers.includes(PersonList.id)}
                    onChange={() => handleUserSelect(PersonList.id)}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {PersonList.userName}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {new Date(PersonList.dateOfBirth).toLocaleDateString()}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {PersonList.mobileNumber}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {calculateAge(PersonList.dateOfBirth)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Typography>

      <Typography  className={classes.buttondiv}>
        <Button
          variant="contained"
          className={
            activeButton === "Back" ? classes.activeItem : classes.button
          }
          style={{borderRadius:"4rem"}}
          disabled={currentPage === 1}
          onClick={() => handleButtonClick("Back")}
        >
          <b>Back</b>
        </Button>
        {currentPage === totalPages ? (
          <Button
            variant="contained"
            className={
              activeButton === "Next" ? classes.activeItem : classes.button
            }
            style={{borderRadius:"4rem"}}
            onClick={handleSubmit}
          >
            <b>Submit</b>
          </Button>
        ) : (
          <Button
            variant="contained"
            className={
              activeButton === "Next" ? classes.activeItem : classes.button
            }
            style={{borderRadius:"4rem"}}
            onClick={() => handleButtonClick("Next")}
          >
            <b>Next</b>
          </Button>
        )}
        {currentPage !== totalPages && (
          <Button
            variant="contained"
            className={classes.activeItem}
            style={{borderRadius:"4rem"}}
            onClick={handleSubmit}
          >
            <b>Submit</b>
          </Button>
        )}
      </Typography>
    </Typography>
  );
};

export default PersonList;
