import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { API_URLS } from "../utils/config";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { Modal } from "@mui/material";
import ContentLoader from "react-content-loader";

const useStyles = makeStyles(() => ({
  container1: {
    padding: "20px",
    height: "auto",
  },
  card: {
    position: "relative",
    width: "auto",
    margin: "0 8px 20px 10px",
    borderRadius: "1.5rem",
    overflow: "hidden",
    cursor: "pointer",
  },
  cardMedia: {
    height: "180px",
    transition: "filter 0.3s ease-in-out",
    "&:hover": {
      filter: "blur(5px)",
    },
  },
  buttonContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "10px 0",
    transition: "opacity 0.3s ease-in-out",
    opacity: 1,
  },
  buttonContainerHovered: {
    opacity: 0,
  },
  offerName: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    fontSize: "1.5rem",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
  offerNameHovered: {
    opacity: 1,
  },
  loader: {
    margin: "auto",
    marginTop: "30%",
    width: "50px",
    aspectRatio: "1",
    borderRadius: "50%",
    border: "8px solid #0000",
    borderRightColor: "#ffa50097",
    position: "relative",
    animation: "$l24 1s infinite linear",
  },
  "@keyframes l24": {
    "100%": { transform: "rotate(1turn)" },
  },
  "@global": {
    ".loader::before, .loader::after": {
      content: '""',
      position: "absolute",
      inset: "-8px",
      borderRadius: "50%",
      border: "inherit",
      animation: "inherit",
      animationDuration: "2s",
    },
    ".loader::after": {
      animationDuration: "4s",
    },
  },
}));

const OfferCard = React.memo(({ offer, onClick, onDelete }) => {
  const classes = useStyles();
  const [admin, setAdmin] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin");
    if (isAdmin === "true") {
      setAdmin(true);
    }
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleDeleteItemClick = (id) => {
    setDeleteItemId(id);
    setDeleteOpen(true);
  };

  const handleDeleteConfirmation = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const id = deleteItemId;
      const response = await fetch(
        `${API_URLS.BASE_URL}/delete-offer-type/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        setDeleteError("Failed to delete item");
        setOpen(true);
        throw new Error("Failed to delete item");
      } else {
        onDelete(id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        className={classes.card}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {admin && (
          <>
            <DeleteOutlineIcon onClick={() => handleDeleteItemClick(offer.id)}>
              Delete
            </DeleteOutlineIcon>
            <Modal
              open={deleteOpen}
              onClose={() => setDeleteOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: 300, bgcolor: "background.paper", p: 4 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to delete this item?
                </Typography>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    onClick={() => setDeleteOpen(false)}
                    color="primary"
                    variant="contained"
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDeleteConfirmation}
                    color="error"
                    variant="contained"
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Modal>
          </>
        )}
        <CardActionArea onClick={onClick}>
          <CardMedia
            className={classes.cardMedia}
            image={offer.image}
            title={offer.name}
          />
          <div
            className={`${classes.buttonContainer} ${
              isHovered ? classes.buttonContainerHovered : ""
            }`}
          >
            <Button variant="text" style={{ color: "white" }}>
              <b>{offer.name}</b>
            </Button>
          </div>
          <Typography
            className={`${classes.offerName} ${
              isHovered ? classes.offerNameHovered : ""
            }`}
          >
            {offer.name}
          </Typography>
        </CardActionArea>
      </Card>

      {deleteError && (
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {deleteError}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  );
});

const OfferType = () => {
  const [offerType, setOfferType] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleOfferSelection = useCallback(
    (offerId) => {
      const isAdmin = localStorage.getItem("isAdmin");
      if (isAdmin === "true") {
        return;
      }
      navigate(`/offer/offer-type/${offerId}/crate-offer?offerId=${offerId}`);
    },
    [navigate]
  );

  const buttonClicked = useCallback(() => {
    const isAdmin = localStorage.getItem("isAdmin");
    if (isAdmin === "true") {
      navigate("/hotel-details");
    } else {
      navigate("/today-offer");
    }
  }, [navigate]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    const fetchOfferTypes = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          `${API_URLS.BASE_URL}/List-of-offer-type`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const updatedOfferTypes = await Promise.all(
          data.data.map(async (offer) => {
            console.log("<><>jdsdush", offer);
            const imageResponse = await fetch(
              `${API_URLS.BASE_URL}/offer-type/image/${offer.id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            console.log("imageResponse......", imageResponse);

            if (!imageResponse.ok) {
              throw new Error(`HTTP error! Status: ${imageResponse.status}`);
            }

            const imageBlob = await imageResponse.blob();
            const imageObjectURL = URL.createObjectURL(imageBlob);
            return { ...offer, image: imageObjectURL };
          })
        );

        setOfferType(updatedOfferTypes);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchOfferTypes();
  }, []);

  const handleDeleteOffer = (id) => {
    setOfferType((prevOfferTypes) =>
      prevOfferTypes.filter((offer) => offer.id !== id)
    );
  };

  if (loading) {
    return (
      <Grid container spacing={3} className={classes.container1}>
        {[...Array(8)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <ContentLoader
              speed={2}
              width={270}
              height={180}
              viewBox="0 0 300 180"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="10" ry="10" width="300" height="180" />
            </ContentLoader>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Typography
      style={{
        margin: "2%",
        backgroundColor: "#FCB98E",
        borderRadius: 20,
        padding: "1%",
        height: "auto",
        textAlign: "center",
      }}
    >
      <ArrowBackIcon
        variant="button"
        onClick={buttonClicked}
        style={{
          color: "white",
          display: "flex",
          height: "max-content",
          position: "absolute",
          marginTop: "5px",
          marginLeft: "20px",
          cursor: "pointer",
        }}
      />
      <h3 style={{ color: "#463636" }}>Select Offer Category</h3>
      <Grid container spacing={3} className={classes.container1}>
        {offerType.map((offer) => (
          <OfferCard
            key={offer.id}
            offer={offer}
            onClick={() => handleOfferSelection(offer.id)}
            onDelete={handleDeleteOffer}
          />
        ))}
      </Grid>
    </Typography>
  );
};

export default OfferType;
