// firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDb_FceOEPC3gTJgTKsyJbjzB0qVj-DaL4",
  authDomain: "surprises-ed7ec.firebaseapp.com",
  projectId: "surprises-ed7ec",
  storageBucket: "surprises-ed7ec.appspot.com",
  messagingSenderId: "358514726354",
  appId: "1:358514726354:android:0075f5fe1db620a7b3240b"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

export { db };

