import React, { Suspense, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom';
import Offer from '../components/todayOffer';
import Login from '../components/login';
import History from '../components/history';
import Users from '../components/userList';
import Profile from '../components/profile';
import Setting from '../components/setting';
import ProtectedRoute from './ProtectedRoute';
import CrateOffer from '../components/crateOffer';
import OfferType from '../components/offerType';
import UserForm from '../components/userForm'
import AcceptedOfferComponent from '../components/acceptedOffer';
import NotResponsedOfferComponent from '../components/notResponsedOffer';
import PersonList from '../components/personList';
import AddItem from '../components/add_Item_form';
import UpdateHotel from '../components/crateOffer';
import AddHotel from '../components/add_hotel';
import CategoryType from "../components/categotyForm";
import AddCategory from "../components/itemCategory";
import OfferSubType from "../components/subTypeCategory";
import HotelDetails from '../components/hotelDetails';
import ShowSubType from "../components/offerSubType"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {AxiosInterceptorProvider} from "./useAxiosInterceptors";
import InquiryForm from '../components/inquiry';
import InquiryHistory from '../components/inquiryHistory';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const Routs = () => {
    const AllRoutes = useMemo(() => React.lazy(() => import("../components/Dashboard")))
 
    return (
        <ThemeProvider theme={theme}> 
            <AxiosInterceptorProvider>
        <Routes>

        <Route path="/inquiry-form" element={<InquiryForm />} />
        <Route path="/inquiryHistory" element={<InquiryHistory />} />
            <Route path="/login" element={<Login />} />
           <Route path="/" element={<Login />} />
            <Route path="/update-item/:itemId" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><AddItem /> </AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/re-active-offer/:reActiveOfferId" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><CrateOffer /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/offer/offer-type/:offerId/crate-offer" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><CrateOffer /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/person-list" element={<PersonList />} />
            <Route path="/add-item" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><AddItem /> </AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/update-hotel" element={<UpdateHotel /> } />
            <Route path="/hotel-details" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><HotelDetails /></AllRoutes></Suspense></ProtectedRoute>} />
             <Route path="/subtype-details" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><ShowSubType /></AllRoutes></Suspense></ProtectedRoute>} />
             <Route path="/add-category" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><AddCategory /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/add-hotel" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><AddHotel /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/today-offer" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><Offer /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/accepted-offers" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><AcceptedOfferComponent /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/pending-offers" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><NotResponsedOfferComponent /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/offer/offer-type" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><OfferType /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/add-user" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><UserForm /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/offer" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><Offer /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/history" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><History /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/users" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><Users /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><Profile /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/setting" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><Setting /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/type" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><CategoryType /></AllRoutes></Suspense></ProtectedRoute>} />
            <Route path="/subtype" element={<ProtectedRoute><Suspense fallback="...loading"><AllRoutes><OfferSubType /></AllRoutes></Suspense></ProtectedRoute>} />

        </Routes>
        </AxiosInterceptorProvider>
        </ThemeProvider>
    )
}

export default Routs;