

import React, { createContext, useContext, useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Typography, Modal } from '@mui/material';

const AxiosInterceptorContext = createContext();

export const AxiosInterceptorProvider = ({ children }) => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [isDisable, setIsDisable] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
        
      };

    useEffect(() => {
        const fetchInterceptor = async (response) => {
            if (!response.ok && response.status === 401) {
               setModalOpen(true);
                navigate('/login');
            }
            if (!response.ok && response.status === 301) {
                setModalOpen(true);
                setIsDisable(true);
                 navigate('/login');
             }
            return response;
        };

        const originalFetch = window.fetch;
        window.fetch = async (url, options) => {
            try {
                const response = await originalFetch(url, options);
                return fetchInterceptor(response);
            } catch (error) {
                return Promise.reject(error);
            }
        };

        // Clean up the interceptor when the component unmounts
        return () => {
            window.fetch = originalFetch;
        };
    }, [navigate]);

    return (
        <div>
        <AxiosInterceptorContext.Provider value={{}}>
            {children}
        </AxiosInterceptorContext.Provider>
         <Modal open={modalOpen} onClose={handleClose}>
         <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
           {!isDisable && <Typography variant="h6" gutterBottom>Your session is expired. Please sign in again</Typography>}
           {isDisable &&  <Typography variant="h6" gutterBottom>Your Account Is Disabled.</Typography> }
         </div>
       </Modal>
       </div>
    );
};

export const useAxiosInterceptor = () => {
    return useContext(AxiosInterceptorContext);
};
