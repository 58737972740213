import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import Login from './components/login';
import TodayOffer from './components/todayOffer'
import UserList from './components/userList'
import Routs from './common/Routs';





function App() {
 
  return (
    
    <Router>
      
    <Routs />
    
</Router>


    
  );
}

export default App;
